import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UserDTO } from '@/data/dto/user.dto';
import RouterUtil from '@/utils/router.util';
import { RouteConfig } from 'vue-router';
import router from '@/router';
import { SessionDTO } from '@/data/dto/session.dto';
import { CredentialsDTO } from '@/data/dto/credentials.dto';
import MOMABffService from '@/services/moma-bff.service';

@Module
export default class UserModule extends VuexModule {
  bffService = new  MOMABffService();
  isLogged = !!window.localStorage.getItem('token');
  isSimulatingUser = !!window.localStorage.getItem('simulated-token');
  currentUser?: UserDTO = undefined;
  userRoutes: RouteConfig[] = [];

  @Action
  async login(credentials: CredentialsDTO): Promise<void> {
    await this.context.dispatch('logout');
    const session = await this.bffService.login(credentials);
    this.context.commit('loginSuccess', session);
  }

  @Action
  async authorize() {
    return this.bffService.authorize()
      .then((user) => {
        this.context.commit('setCurrentUser', user);
        this.context.commit('setUserRoutes', { user, routes: router.options.routes });
      })
      .catch(() => {
        this.context.dispatch('logout');
      });
  }

  @Action
  getAllUsers() {
    return this.bffService.getAllUsers()
      .then(users => users);
  }

  @Action
  getOneUser(email: string) {
    return this.bffService
      .getOneUser(email);
  }

  @Action
  createUser(user: any) {
    return this.bffService
      .createUser(user.email, user.name, user.groups);
  }

  @Action
  updateUserStatus(user: any) {
    return this.bffService
      .updateUserStatus(user.email, user.active);
  }


  @Action
  updateUserName(user: any) {
    return this.bffService
      .updateUserName(user.email, user.name);
  }

  @Action
  deleteUser(user: any) {
    return this.bffService
      .deleteUser(user.email)
  }

  @Action
  addUserToApplicationGroup(payload: any) {
    return this.bffService
      .addUserToApplicationGroup(payload.email, payload.groups);
  }

  @Action
  removeUserFromApplicationGroup(payload: any) {
    return this.bffService
      .removeUserFromApplicationGroup(payload.email, payload.groupUUID);
  }

  @Action
  logout(): void {
    this.context.commit('clearSession');
    this.context.commit('clearCurrentUser');
    this.context.commit('clearUserRoutes');
  }

  @Action
  async simulateUser(email: string): Promise<void> {
    const { token } = await this.bffService.simulateUser(email);
    this.context.commit('setSimulatedUserSession', token);
  }

  @Action
  logoutSimulatedUser(): void {
    this.context.commit('removeSimulatedUserSession');
    this.context.commit('clearCurrentUser');
    this.context.commit('clearUserRoutes');
  }

  @Mutation
  setCurrentUser(user: UserDTO) {
     this.currentUser = user;
  }


  @Mutation
  clearCurrentUser() {
    this.currentUser = undefined;
  }

  @Mutation
  setUserRoutes(payload: { user: UserDTO, routes: RouteConfig[] }) {
    this.userRoutes = RouterUtil.identifyUserAllowedRoutes(payload.user, payload.routes);
  }

  @Mutation
  clearUserRoutes() {
    this.userRoutes = [];
  }

  @Mutation
  loginSuccess(session: SessionDTO) {
    window.localStorage.setItem('token', session.token);
    window.localStorage.setItem('refreshToken', session.refreshToken);
    this.isLogged = true;
  }

  @Mutation
  clearSession() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('simulated-token');
    window.localStorage.removeItem('refreshToken');
    this.isLogged = false;
  }

  @Mutation
  setSimulatedUserSession(simulatedUserToken: string): void {
    window.localStorage.setItem('simulated-token', simulatedUserToken);
    this.isSimulatingUser = true;
  }

  @Mutation
  removeSimulatedUserSession(): void {
    window.localStorage.removeItem('simulated-token');
    this.isSimulatingUser = false;
  }
}
