
import PaginationItem from "@/components/PaginationItem.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import { TwitterCrisisMonitorAttacksCountByMentionsItemDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-mentions.dto";
import SingleBarChart from "@/components/SingleBarChart.vue";
import Pagination from "@/mixins/pagination.mixin";
import { AttacksCountByMentionsStoreContract } from "@/data/store/attacks-count-by-mentions.store.contract";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
    SingleBarChart,
  },
})
export default class AttacksCountByMentions extends Mixins(Pagination) {
  @Prop()
  private attacksCountByMentionsStore!: AttacksCountByMentionsStoreContract;
  @Prop({ default: "" }) private startDate!: string;
  @Prop({ default: "" }) private endDate!: string;

  get attacksCountByMentions(): TwitterCrisisMonitorAttacksCountByMentionsItemDTO[] {
    return this.attacksCountByMentionsStore.paginated;
  }

  get attacksCountByMentionsCountItems(): number {
    return this.attacksCountByMentionsStore.totalCount;
  }

  get paginationLimit(): number {
    return this.attacksCountByMentionsStore.paginationLimit;
  }

  get attacksCountByMentionsMaxAttack(): number {
    return this.attacksCountByMentionsStore.maxAttacksCount;
  }

  get isLoading(): boolean {
    return this.attacksCountByMentionsStore.isLoading;
  }

  async getAttacksCountByMentions(offset = 0): Promise<void> {
    await this.attacksCountByMentionsStore.fetch({
      pagination: {
        offset,
        limit: this.paginationLimit,
      },
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getAttacksCountByMentions(0);
  }
}
