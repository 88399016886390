var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"section-card",class:{ blocked: _vm.blocked, isNew: _vm.isNew }},[_c('a',{class:{
      'is-first-card': _vm.isFirstCard,
      'is-last-card': _vm.isLastCard,
    },style:({
      background: _vm.generateGradient(_vm.customColors),
      //height: cardSize, 
    }),on:{"click":function($event){return _vm.goToLink(_vm.link, _vm.blocked, _vm.externalLink)}}},[_c('div',{staticClass:"icons-wrapper"},_vm._l((_vm.icons),function(icon,i){return _c('div',{key:i,staticClass:"card-icon"},[_c('CustomIcon',{attrs:{"icon":icon,"size":25}})],1)}),0),_c('b-row',{staticClass:"h-100",attrs:{"align-v":"stretch"}},[_c('b-col',{staticClass:"text-wrapper",attrs:{"cols":"12","lg":"6"}},[(_vm.cardTitle)?_c('header',[_c('h1',[_vm._v(_vm._s(_vm.cardTitle))]),_vm._l((_vm.cardSubtitles),function(subtitle,i){return _c('h2',{key:i},[_vm._v(" "+_vm._s(subtitle)+" ")])})],2):_vm._e(),(_vm.text)?_c('main',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }