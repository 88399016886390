import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export type IAlertTypes = 'primary' | 'danger' | 'warning' | 'success';
export type IAlert = { message: string; type: IAlertTypes; dismissCountDown: number; };
export type IAlertOptions = { message: string; type?: IAlertTypes; timeout?: number; };

@Module
export default class SystemModule extends VuexModule {
  
  isLoading = false;
  loadingCount = 0;

  alert: IAlert = {
    message: '',
    type: 'primary',
    dismissCountDown: 0,
  };

  @Action
  showFakeLoading(seconds = 1): void {
    const duration = seconds * 1000;
    this.context.commit('loadingStart');
    setTimeout(() => {
      this.context.commit('loadingEnd');
    }, duration);
  }

  @Mutation
  loadingStart(): void {
    this.loadingCount++;
    if (!this.isLoading) this.isLoading = true;
  }

  @Mutation
  loadingEnd(): void {
    this.loadingCount--;
    if (this.loadingCount === 0) this.isLoading = false;
  }

  @Mutation
  showAlert(options: IAlertOptions): void {
    this.alert.message = options.message;
    this.alert.type = options.type || 'primary';
    this.alert.dismissCountDown = options.timeout || 10;
  }

  @Mutation
  hideAlert(): void {
    this.alert.message = '';
    this.alert.type = 'primary';
    this.alert.dismissCountDown = 0;
  }
}
