
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import SearchBox from "@/components/SearchBox.vue";
import { RecycleScroller } from "vue-virtual-scroller";

//todo: refinar para aceitar coleções de objetos ou de valores primitivos
type CollectionItem = { [key: string]: any };

type CollectionGroup = { title?: string; collection: CollectionItem[] };

type SelectionType = "single" | "multiple";

@Component({
  components: {
    SearchBox,
    RecycleScroller,
  },
})
export default class CustomVirtualScrollerSelectList extends Vue {
  @Prop(Array) private collection!: CollectionItem[] | CollectionGroup[];
  @Prop({ default: "single" }) private type!: SelectionType;
  @Prop({ default: "auto" }) private height!: string;
  @Prop({ default: "value" }) private keyField!: string;
  @Prop({ default: true }) private showFilter!: boolean;
  @Prop({ default: true }) private showCount!: boolean;
  @Prop({ default: false }) private showSelectAll!: boolean;
  @Prop() private customLabel?: (item: CollectionItem) => string;
  @Prop() private customTitle?: (item: CollectionItem) => string;
  @Prop() private customValue?: (item: CollectionItem) => any;
  @Prop({ default: () => [] }) private searchFields?: string[];
  @Prop() private value!: any;
  @Prop({ default: false }) private labelUseHtml!: boolean;
  @Prop({ default: false }) private dark!: boolean;
  @Prop() labelSelectAll!: string;

  listedItems: CollectionItem[] = [];

  multipleSelection =
    this.isMultiple && Array.isArray(this.value) ? [...this.value] : [];

  get uniqueId(): string {
    return "_" + Math.random().toString(36).substr(2, 9);
  }

  get isMultiple(): boolean {
    return this.type === "multiple";
  }

  get isSelectedAll(): boolean {
    return this.multipleSelection.length === this.collection.length;
  }

  set isSelectedAll(isSelected: boolean) {
    if (isSelected) {
      this.selectAll();
      return;
    }

    if (this.isSelectedAll) {
      this.unselectAll();
    }
  }

  selectAll(): void {
    this.multipleSelection = this.collection.map((item) =>
      this.customValue ? this.customValue(item) : item
    );
  }

  unselectAll(): void {
    this.multipleSelection = [];
  }

  onSearchFinished(result: CollectionItem[]): void {
    this.listedItems = result;
  }

  resetList(): void {
    this.listedItems = this.collection;
  }

  resetValue(): void {
    if (this.isMultiple) {
      if (this.multipleSelection.length) {
        this.multipleSelection = [];
      }
      return;
    }

    this.$emit("input", undefined);
  }

  onSingleSelection(item: CollectionItem): void {
    this.$emit("input", this.customValue ? this.customValue(item) : item);
  }

  @Watch("multipleSelection")
  onMultipleSelectionChange(): void {
    this.$emit("input", this.multipleSelection);
  }

  isActive(item: CollectionItem): boolean {
    return this.customValue
      ? this.value === this.customValue(item)
      : this.value === item;
  }

  get mappedSearchList(): CollectionItem[] {
    return this.collection;
  }

  @Watch("collection")
  onCollectionChanged(): void {
    this.resetList();
    this.resetValue();
  }

  @Watch("value")
  onValueChanged(value: any): void {
    if (this.isMultiple) this.multipleSelection = value;
  }

  mounted(): void {
    this.resetList();
  }
}
