import routesJournalism from "./routesJournalism";
import routesMasterBrand from "./routesMasterBrand";
import routesNovels from "./routesNovels";
import routesSports from "./routesSports";
import routesStreaming from "./routesStreaming";
import routesEntertainmentAndVarieties from "./routesEntertainmentAndVarieties";
import routesFilmsAndSeries from "./routesFilmsAndSeries";
import routesChildren from "./routesChildren";

const EmptyRouterView = () => import(/* webpackChunkName: "EmptyRouterView" */ "@/components/EmptyRouterView.vue");

const SectionCategoriesKpiCards = () => import(/* webpackChunkName: "SectionCategoriesKpiCards" */ "@/views/KPIs/SectionCategoriesKpiCards.vue");

export default {
  path: "/kpi-marcas",
  component: EmptyRouterView,
  meta: {
    labels: ["Pesquisas", "de Marcas"],
    description:
      "Sistema de monitoramento das marcas Globo e concorrentes realizado através de pesquisas quantitativas contemplando arenas core, como entretenimento e informação (masterbrand) e streaming, além de arenas de conteúdo, como jornalismo, esportes, novelas, entretenimento e variedades, filmes e séries e infantil.",
    customColors: ["#ED1D90"],
    icons: ["Bar_Chart_4_Bars"],
  },
  children: [
    {
      path: "",
      name: "kpi-marcas",
      component: SectionCategoriesKpiCards,
      meta: {
        labels: ["Pesquisas de Marcas"],
        description: "",
        hiddenOnNavigation: true,
      },
    },
    routesMasterBrand,
    routesStreaming,
    routesNovels,
    routesJournalism,
    routesSports,
    routesEntertainmentAndVarieties,
    routesFilmsAndSeries,
    routesChildren,    
  ],
};
