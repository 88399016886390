import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import RouterUtil from "@/utils/router.util";
import routesKPIs from "./KPI/routesKPIs";
import routesCrisisMonitor from "./routesCrisisMonitor";

Vue.use(VueRouter);

const Login = () => import(/* webpackChunkName: "Login" */ "@/views/Login/Login.vue");

const UserNotAuthorized = () =>  import(/* webpackChunkName: "UserNotAuthorized" */ "@/views/Login/UserNotAuthorized.vue");

const EmptyRouterView = () =>  import(/* webpackChunkName: "EmptyRouterView" */ "@/components/EmptyRouterView.vue");

const Users = () => import(/* webpackChunkName: "Users" */ "@/views/Admin/Users/Users.vue");

const NotFound = () => import(/* webpackChunkName: "NotFound" */ "@/views/NotFound.vue");

const Groups = () => import(/* webpackChunkName: "Groups" */ "@/views/Admin/Groups/Groups.vue");

const Logout = () => import(/* webpackChunkName: "Logout" */ "@/views/Login/Logout.vue");


const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    meta: {
      hiddenOnNavigation: true,
    },
    component: Home,
  },
  routesKPIs,
  //routesCrisisMonitor,  

  //Admin
  {
    path: "/admin",
    name: "admin",
    component: EmptyRouterView,
    redirect: "/admin/usuarios",
    meta: {
      labels: ["Administração"],
    },
    children: [
      {
        path: "usuarios",
        name: "users",
        component: Users,
        meta: {
          labels: ["Usuários"],
          customColors: ["#FF7A13", "#FF2C44", "#FF1B7B", "#AD22D7"],
          requiresPermission: "isPermissionManager",
        },
      },
      {
        path: "regras",
        name: "groups",
        component: Groups,
        meta: {
          labels: ["Regras"],
          customColors: ["#FF7A13", "#FF2C44", "#FF1B7B", "#AD22D7"],
          requiresPermission: "isPermissionManager",
        },
      },
    ],
  },
    
  //Login
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      labels: ["Login"],
      hiddenOnNavigation: true,
      customColors: ["#02EBFE", "#1297DF", "#6C66ED"],
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      hiddenOnNavigation: true,
      customColors: ["#02EBFE", "#1297DF", "#6C66ED"],
    },
  },
  // Generic routes
  {
    path: "/nao-autorizado",
    name: "userNotAuthorized",
    component: UserNotAuthorized,
    meta: {
      hiddenOnNavigation: true,
    },
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      hiddenOnNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  const { isLogged, currentUser } = store.state.user;
  const isUnprotectedRoute = (name: string | null | undefined) => {
    if (name) return ["login", "logout"].includes(name);
    return false;
  };

  // Se for uma das rotas desprotegidas continua, caso contrário inicia as validações
  if (isUnprotectedRoute(to.name)) {
    next();
  } else {
    // Se o usuário não estiver logado, direciona para o login
    if (!isLogged) {
      next({ name: "login" });
      return;
    }

    // Se houver sessão, mas o usuário não estiver armazenado na store, busca seus dados
    if (isLogged && currentUser === undefined) {
      await store.dispatch("authorize");
    }

    // Verifica se o usuário tem permissão para a rota
    RouterUtil.isNextRouteAllowed(to.path, store.state.user.userRoutes)
      ? next()
      : next({ name: "userNotAuthorized" });
  }
});

export default router;
