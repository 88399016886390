
import { Component, Vue, Prop } from "vue-property-decorator";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

@Component({
  components: {
    LoadingOverlay,
  },
})
export default class CrisisMonitorCard extends Vue {
  @Prop({ default: false }) private isLoading!: boolean;
}
