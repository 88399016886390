import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class PaginationMixin extends Vue {
  currentPage = 1;
  itensPerPage = 10;

  itensInCurrentPage<T>(list: T[]): T[] {
    const firstIndex = (this.currentPage - 1) * this.itensPerPage;
    const lastIndex = this.currentPage * this.itensPerPage - 1;
    return list.filter((_, index) => index >= firstIndex && index <= lastIndex);
  }

  getOffsetBasedInCurrentPage(
    currentPage: number,
    paginationLimit: number,
    totalItems: number
  ): number {
    const itemsPerPage = (currentPage - 1) * paginationLimit;
    const offset =
      itemsPerPage <= totalItems ? itemsPerPage : itemsPerPage - totalItems;
    return offset;
  }
}
