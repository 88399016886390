import { TwitterCrisisMonitorLastHoursDiagnosisDTO } from "@/data/dto/twitter-crisis-monitor/last-hours-diagnosis.dto";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import MOMABffService from "@/services/moma-bff.service";

type BasicState<T> = {
  loading: boolean;
  data: T | null;
  error: string | null;
};

type LocalState = BasicState<TwitterCrisisMonitorLastHoursDiagnosisDTO>;

@Module({
  dynamic: true,
  namespaced: true,
  name: "LastHourDiagnosisStore",
  store,
})
export class LastHourDiagnosisStore extends VuexModule {
  bffService = new MOMABffService();

  paginationLimit = 10;

  _state: LocalState = {
    loading: false,
    error: null,
    data: null,
  };

  get isLoading(): boolean {
    return this._state.loading;
  }

  get all(): TwitterCrisisMonitorLastHoursDiagnosisDTO | null {
    return this._state.data;
  }

  @Mutation
  updateLastHoursDiagnosis(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload,
    };
  }

  @Action
  async getLastHoursDiagnosis(): Promise<void> {
    await this._getLastHoursDiagnosis();
  }

  @Action
  async _getLastHoursDiagnosis(): Promise<void> {
    this.updateLastHoursDiagnosis({
      loading: true,
      error: null,
    });

    try {
      const response =
        await this.bffService.getTwitterCrisisMonitorRealtimeLastHoursDiagnosis();
      this.updateLastHoursDiagnosis({
        data: response,
      });
    } catch (error: any) {
      this.updateLastHoursDiagnosis({
        error: error?.message,
      });
    } finally {
      this.updateLastHoursDiagnosis({
        loading: false,
      });
    }
  }
}
