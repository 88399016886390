
import OverviewPanel from "@/components/TwitterCrisisMonitor/OverviewPanel.vue";
import AttacksCountByTerms from "@/components/TwitterCrisisMonitor/AttacksCountByTerms.vue";
import AttacksCountByBrands from "@/components/TwitterCrisisMonitor/AttacksCountByBrands.vue";
import AttacksCountByMentions from "@/components/TwitterCrisisMonitor/AttacksCountByMentions.vue";
import AttacksCountByHashtags from "@/components/TwitterCrisisMonitor/AttacksCountByHashtags.vue";
import LastHourDiagnosis from "@/components/TwitterCrisisMonitor/LastHourDiagnosis.vue";
import AttacksEvolutionByHour from "@/components/TwitterCrisisMonitor/AttacksEvolutionByHour.vue";
import BiggestInfluencers from "@/components/TwitterCrisisMonitor/BiggestInfluencers.vue";
import AttacksOfGreaterRepercussion from "@/components/TwitterCrisisMonitor/AttacksOfGreaterRepercussion.vue";
import MainDetractors from "@/components/TwitterCrisisMonitor/MainDetractors.vue";
import TwitterCrisisMonitorFooter from "@/components/TwitterCrisisMonitor/TwtiterCrisisMonitorFooter.vue";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { AttacksCountByHashtagsStore } from "@/store/modules/twitter-crisis-monitor/realtime/attacks-count-by-hashtags";
import { AttacksCountByBrandsStore } from "@/store/modules/twitter-crisis-monitor/realtime/attacks-count-by-brands";
import { AttacksCountByMentionsStore } from "@/store/modules/twitter-crisis-monitor/realtime/attacks-count-by-mentions";
import { AttacksCountByTermsStore } from "@/store/modules/twitter-crisis-monitor/realtime/attacks-count-by-terms";
import { RealtimeOverviewStore } from "@/store/modules/twitter-crisis-monitor/realtime/overview";
import { AttacksCountByHashtagsStoreContract } from "@/data/store/attacks-count-by-hashtags.store.contract";
import { AttacksCountByBrandsStoreContract } from "@/data/store/attacks-count-by-brands.store.contract";
import { AttacksCountByMentionsStoreContract } from "@/data/store/attacks-count-by-mentions.store.contract";
import { AttacksCountByTermsStoreContract } from "@/data/store/attacks-count-by-terms.store.contract";
import { OverviewStoreContract } from "@/data/store/overview.store.contract";
import { RealtimeMainDetractorsStore } from "@/store/modules/twitter-crisis-monitor/realtime/main-detractors";
import { RealtimeAttacksOfGreaterRepercussionStore } from "@/store/modules/twitter-crisis-monitor/realtime/attacks-of-greater-repercussion";
import { FilterStoreContract } from "@/data/store/filter.store.contract";
import { MainDetractorsStoreContract } from "@/data/store/main-detractors.store.contract";
import { BiggestInfluencersStoreContract } from "@/data/store/biggest-influencers-store-contract";
import { BiggestInfluencersStore } from "@/store/modules/twitter-crisis-monitor/realtime/biggest-influencers";

@Component({
  components: {
    OverviewPanel,
    AttacksCountByTerms,
    AttacksCountByBrands,
    AttacksCountByMentions,
    AttacksCountByHashtags,
    LastHourDiagnosis,
    AttacksEvolutionByHour,
    BiggestInfluencers,
    AttacksOfGreaterRepercussion,
    MainDetractors,
    TwitterCrisisMonitorFooter,
  },
})
export default class RealtimePage extends Vue {
  getTitleAttacksOfGreaterRepercussion = "ataques de maior repercussão"
  getTitleMainDetractors = "principais detratores"

  public realtimeOverviewStore: OverviewStoreContract = getModule(
    RealtimeOverviewStore,
    this.$store
  );

  public biggestInfluencersStore: BiggestInfluencersStoreContract = getModule(
    BiggestInfluencersStore,
    this.$store
  );

  attacksOfGreaterRepercussionStore = getModule(
    RealtimeAttacksOfGreaterRepercussionStore,
    this.$store
  );

  public mainDetractorsStore: MainDetractorsStoreContract &
    FilterStoreContract = getModule(RealtimeMainDetractorsStore, this.$store);

  public attacksCountByHashtagsStore: AttacksCountByHashtagsStoreContract &
    FilterStoreContract = getModule(AttacksCountByHashtagsStore, this.$store);

  public attacksCountByBrandsStore: AttacksCountByBrandsStoreContract &
    FilterStoreContract = getModule(AttacksCountByBrandsStore, this.$store);

  public attacksCountByMentionsStore: AttacksCountByMentionsStoreContract &
    FilterStoreContract = getModule(AttacksCountByMentionsStore, this.$store);

  public attacksCountByTermsStore: AttacksCountByTermsStoreContract &
    FilterStoreContract = getModule(AttacksCountByTermsStore, this.$store);
}
