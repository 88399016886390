
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Highcharts from "highcharts";
import {
  TwitterCrisisMonitorAttacksEvolutionByHourDTO,
  TwitterCrisisMonitorAttacksEvolutionByHourItemDTO,
} from "@/data/dto/twitter-crisis-monitor/attacks-evolution-by-hour.dto";

@Component
export default class AttacksEvolutionByHourChart extends Vue {
  @Prop() private series!: TwitterCrisisMonitorAttacksEvolutionByHourDTO;
  chartOptions: Highcharts.Options = {};

  attacksEvolutionAxisY!: number[];
  attacksEvolutionAxisX!: string[];

  mounted(): void {
    this.attacksEvolutionAxisY = this.series.attacksEvolutionByHour.map(
      (attack: TwitterCrisisMonitorAttacksEvolutionByHourItemDTO) =>
        attack.attacksCount
    );
    this.attacksEvolutionAxisX = this.series.attacksEvolutionByHour.map(
      (attack: TwitterCrisisMonitorAttacksEvolutionByHourItemDTO): string => {
        let attackDateFormat: Date = new Date(attack.date);
        attackDateFormat.setHours(attack.hour);
        return attackDateFormat.toLocaleDateString("pt-BR", {
          hour: "numeric",
          month: "long",
          day: "numeric",
        });
      }
    );
    this.renderChart();
  }

  @Watch("series")
  renderChart(): void {
    this.chartOptions = this.generateChartOptions(this);
  }

  generateChartOptions(
    context: AttacksEvolutionByHourChart
  ): Highcharts.Options {
    return {
      chart: {
        type: "line",
        backgroundColor: "transparent",
      },
      title: {
        text: "",
        style: {
          color: "#fff",
          fontSize: "12px",
        },
      },
      xAxis: {
        gridLineWidth: 1,
        gridLineColor: "#fff",
        tickmarkPlacement: "on",
        tickInterval: 6,
        categories: context.attacksEvolutionAxisX,
        title: {
          text: "hora do dia",
          y: 15,
          style: {
            color: "#fff",
          },
        },
        labels: {
          style: {
            color: "#fff",
          },
        },
      },
      yAxis: {
        visible: true,
        title: {
          text: "ataques",
          x: -15,
          style: {
            color: "#fff",
          },
        },
        labels: {
          style: {
            color: "#fff",
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: "ataques",
          color: "#fff",
          type: "line",
          data: context.attacksEvolutionAxisY,
        },
      ],
      credits: {
        enabled: false,
      },
    };
  }
}
