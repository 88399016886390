
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Highcharts from "highcharts";
import { TwitterCrisisMonitorLastHoursDiagnosisDTO } from "@/data/dto/twitter-crisis-monitor/last-hours-diagnosis.dto";

@Component
export default class LastHourDiagnosisChart extends Vue {
  @Prop() private series!: TwitterCrisisMonitorLastHoursDiagnosisDTO;
  chartOptions: Highcharts.Options = {};

  mounted(): void {
    this.renderChart(this.series);
  }

  @Watch("series")
  renderChart(series: TwitterCrisisMonitorLastHoursDiagnosisDTO): void {
    this.chartOptions = this.generateChartOptions(series);
  }

  generateChartOptions(
    series: TwitterCrisisMonitorLastHoursDiagnosisDTO
  ): Highcharts.Options {
    return {
      chart: {
        type: "column",
        backgroundColor: "transparent",
      },
      title: {
        text: "",
      },
      tooltip: {
        enabled: true,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: ["ataques até o momento", "previsão para os próx. 10min"],
        labels: {
          useHTML: true,
          style: {
            color: "#fff",
            whiteSpace: "nowrap",
          },
        },
        crosshair: false,
      },
      yAxis: {
        min: 0,
        gridLineColor: "transparent",
        max: series.lastHourDiagnosis.crisisLevels.level3,
        tickAmount: 0,
        plotLines: [
          {
            color: "#FF0000",
            width: 1,
            label: {
              useHTML: true,
              text: '<div style="border:1px; background-color:red;padding:2px;">crise nível 3</div>',
              align: "center",
              y: 10,
              x: 16,
              style: {
                color: "#fff",
              },
            },
            value: series.lastHourDiagnosis.crisisLevels.level3,
          },
          {
            color: "#FF0000",
            width: 1,
            label: {
              useHTML: true,
              text: '<div style="border:1px; background-color:red;padding:2px;">crise nível 2</div>',
              align: "center",
              y: 10,
              x: 16,
              style: {
                color: "#fff",
              },
            },
            value: series.lastHourDiagnosis.crisisLevels.level2,
          },
          {
            color: "#FF0000",
            width: 1,
            label: {
              useHTML: true,
              text: '<div style="border:1px; background-color:red;padding:2px;">crise nível 1</div>',
              align: "center",
              y: 10,
              x: 16,
              style: {
                color: "#fff",
              },
            },
            value: series.lastHourDiagnosis.crisisLevels.level1,
          },
        ],
        labels: {
          enabled: false,
          style: {
            color: "#fff",
          },
        },
        title: {
          text: "",
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          grouping: false,
          dataLabels: {
            enabled: true,
            x: 40,
            y: 50,
          },
        },
      },
      legend: {
        enabled: false,
      },

      series: [
        {
          type: "column",
          name: "",
          enableMouseTracking: false,
          data: [
            series.lastHourDiagnosis.crisisLevels.level3,
            series.lastHourDiagnosis.crisisLevels.level3,
          ],
          color: "#b2b2b2",
          dataLabels: {
            enabled: false,
          },
        },
        {
          type: "column",
          name: "",
          data: [
            series.lastHourDiagnosis.attacksDiagnosis.attacksSoFar,
            series.lastHourDiagnosis.attacksDiagnosis.prevision,
          ],
          color: "#fff",
        },
      ],
    };
  }
}
