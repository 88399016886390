
import { Component, Mixins } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import Pagination from "@/mixins/pagination.mixin";
import AttacksEvolutionByHourChart from "./AttacksEvolutionByHourChart.vue";
import { TwitterCrisisMonitorAttacksEvolutionByHourDTO } from "@/data/dto/twitter-crisis-monitor/attacks-evolution-by-hour.dto";
import { getModule } from "vuex-module-decorators";
import { AttacksEvolutionByHourStore } from "@/store/modules/twitter-crisis-monitor/realtime/attacks-evolution-by-hour";

@Component({
  components: {
    CrisisMonitorCard,
    AttacksEvolutionByHourChart,
  },
})
export default class AttacksEvolutionByHour extends Mixins(Pagination) {
  attacksEvolutionByHourStore = getModule(
    AttacksEvolutionByHourStore,
    this.$store
  );

  get attacksEvolutionByHour(): TwitterCrisisMonitorAttacksEvolutionByHourDTO | null {
    return this.attacksEvolutionByHourStore.all;
  }

  get isLoading(): boolean {
    return this.attacksEvolutionByHourStore.isLoading;
  }

  async getAttacksEvolutionByHour(): Promise<void> {
    await this.attacksEvolutionByHourStore.getAttacksEvolutionByHour();
  }

  async mounted(): Promise<void> {
    await this.getAttacksEvolutionByHour();
  }
}
