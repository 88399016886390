import {
  TwitterCrisisMonitorAttackFiltersDTO,
  TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO,
  TwitterCrisisMonitorAttacksOfGreaterRepercussionItemDTO,
} from "@/data/dto/twitter-crisis-monitor/attacks-of-greater-repercussion.dto";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  PaginatedRequestParamsDTO,
  ResponseMetaTypeDTO,
} from "@/data/dto/common.dto";
import store from "@/store";
import MOMABffService from "@/services/moma-bff.service";
import { AttacksOfGreaterRepercussionStoreContract } from "@/data/store/attacks-of-greater-repercussion-store.contract";

type BasicState<T> = {
  loading: boolean;
  data: T | null;
  meta?: ResponseMetaTypeDTO;
  error: string | null;
  maxRtCount: number;
};

type LocalState =
  BasicState<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>;

@Module({
  dynamic: true,
  namespaced: true,
  name: "RealtimeAttacksOfGreaterRepercussionStore",
  store,
})
export class RealtimeAttacksOfGreaterRepercussionStore
  extends VuexModule
  implements AttacksOfGreaterRepercussionStoreContract
{
  bffService = new MOMABffService();

  paginationLimit = 10;

  _state: LocalState = {
    loading: false,
    error: null,
    data: null,
    maxRtCount: 100,
  };

  get isLoading(): boolean {
    return this._state.loading;
  }

  get paginated(): TwitterCrisisMonitorAttacksOfGreaterRepercussionItemDTO[] {
    if (!this._state.data) {
      return [];
    }
    return this._state.data.attacksOfGreaterRepercussion;
  }

  get totalCount(): number {
    return this._state.meta?.totalCount || 0;
  }

  get maxRtCount() {
    return this._state.maxRtCount;
  }

  @Mutation
  updateAttacksOfGreaterRepercussionState(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload,
    };
  }

  @Action
  async fetch(payload: {
    pagination: PaginatedRequestParamsDTO;
    filters?: TwitterCrisisMonitorAttackFiltersDTO;
  }): Promise<void> {
    const { pagination, filters } = payload;

    this.updateAttacksOfGreaterRepercussionState({
      loading: true,
      error: null,
    });
    try {
      const response =
        await this.bffService.getTwitterCrisisMonitorRealtimeAttacksOfGreaterRepercussion(
          pagination,
          filters
        );
      this.updateAttacksOfGreaterRepercussionState({
        data: response.data,
        meta: response.meta,
      });
    } catch (error: any) {
      this.updateAttacksOfGreaterRepercussionState({
        error: error?.message,
        meta: undefined,
        data: null,
      });
    } finally {
      this.updateAttacksOfGreaterRepercussionState({
        loading: false,
      });
      await this.updateMaxRtCount(pagination);
    }
  }

  @Action
  async updateMaxRtCount(pagination: PaginatedRequestParamsDTO): Promise<void> {
    if (pagination.offset === 0) {
      if (this._state.data?.attacksOfGreaterRepercussion.length) {
        this.updateAttacksOfGreaterRepercussionState({
          maxRtCount: this._state.data?.attacksOfGreaterRepercussion[0].rtCount,
        });
        return;
      }

      this.updateAttacksOfGreaterRepercussionState({
        maxRtCount: 100,
      });
    }
  }
}
