import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import MOMABffService from "@/services/moma-bff.service";
import {
  TwitterCrisisMonitorBotInfluenceOnAttacksDTO,
  TwitterCrisisMonitorBrandAttacksDTO,
  TwitterCrisisMonitorGeneralRepercussionDTO,
  TwitterCrisisMonitorOverviewDTO,
} from "@/data/dto/twitter-crisis-monitor/overview.dto";
import { OverviewStoreContract } from "@/data/store/overview.store.contract";

type BasicState<T> = {
  loading: boolean;
  data: T | null;
  error: string | null;
};

type LocalState = BasicState<TwitterCrisisMonitorOverviewDTO>;

@Module({
  dynamic: true,
  namespaced: true,
  name: "RealtimeOverviewStore",
  store,
})
export class RealtimeOverviewStore
  extends VuexModule
  implements OverviewStoreContract
{
  bffService = new MOMABffService();

  paginationLimit = 10;

  _state: LocalState = {
    loading: false,
    error: null,
    data: null,
  };

  get isLoading(): boolean {
    return this._state.loading;
  }

  get all(): TwitterCrisisMonitorOverviewDTO | null {
    return this._state.data;
  }

  get generalRepercussionData(): TwitterCrisisMonitorGeneralRepercussionDTO | null {
    return this._state.data?.generalRepercussion || null;
  }

  get brandAttacksData(): TwitterCrisisMonitorBrandAttacksDTO | null {
    return this._state.data?.brandAttacks || null;
  }

  get botInfluenceOnAttacksData(): TwitterCrisisMonitorBotInfluenceOnAttacksDTO | null {
    return this._state.data?.botInfluenceOnAttacks || null;
  }

  @Mutation
  updateOverviewState(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload,
    };
  }

  @Action
  async getOverview(): Promise<void> {
    this.updateOverviewState({
      loading: true,
      error: null,
    });

    try {
      const response =
        await this.bffService.getTwitterCrisisMonitorRealtimeOverview();
      this.updateOverviewState({
        data: response,
      });
    } catch (error: any) {
      this.updateOverviewState({
        error: error?.message,
      });
    } finally {
      this.updateOverviewState({
        loading: false,
      });
    }
  }
}
