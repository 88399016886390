var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CrisisMonitorCard',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{attrs:{"slot":"crisis-monitor-head"},slot:"crisis-monitor-head"},[_vm._v("principais menções")]),_c('div',{attrs:{"slot":"crisis-monitor-body"},slot:"crisis-monitor-body"},[_c('b-row',[_c('b-col',{staticClass:"p-0"},[_c('b-table-simple',{staticClass:"table-grid",attrs:{"hover":"","small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-left"},[_vm._v("menções")]),_c('b-th',{staticClass:"text-center"},[_vm._v("ataques")])],1)],1),_c('b-tbody',_vm._l((_vm.attacksCountByMentions),function(item){return _c('b-tr',{key:item.mention},[_c('b-td',{staticClass:"text-left",attrs:{"title":item.mention}},[_vm._v(_vm._s(item.mention))]),_c('b-td',[_c('SingleBarChart',{attrs:{"currentValue":item.attacksCount,"maxValue":_vm.attacksCountByMentionsMaxAttack}})],1)],1)}),1)],1),_c('PaginationItem',{attrs:{"rows":_vm.attacksCountByMentionsCountItems},on:{"onPageChanged":function($event){_vm.getAttacksCountByMentions(
              _vm.getOffsetBasedInCurrentPage(
                $event,
                _vm.paginationLimit,
                _vm.attacksCountByMentionsCountItems
              )
            )}}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }