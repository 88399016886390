import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import MOMABffService from "@/services/moma-bff.service";
import { TwitterCrisisMonitorAttacksEvolutionByHourDTO } from "@/data/dto/twitter-crisis-monitor/attacks-evolution-by-hour.dto";

type BasicState<T> = {
  loading: boolean;
  data: T | null;
  error: string | null;
};

type LocalState = BasicState<TwitterCrisisMonitorAttacksEvolutionByHourDTO>;

@Module({
  dynamic: true,
  namespaced: true,
  name: "AttacksEvolutionByHourStore",
  store,
})
export class AttacksEvolutionByHourStore extends VuexModule {
  bffService = new MOMABffService();

  paginationLimit = 10;

  _state: LocalState = {
    loading: false,
    error: null,
    data: null,
  };

  get isLoading(): boolean {
    return this._state.loading;
  }

  get all(): TwitterCrisisMonitorAttacksEvolutionByHourDTO | null {
    return this._state.data;
  }

  @Mutation
  updateAttacksEvolutionByHour(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload,
    };
  }

  @Action
  async getAttacksEvolutionByHour(): Promise<void> {
    this.updateAttacksEvolutionByHour({
      loading: true,
      error: null,
    });

    try {
      const response =
        await this.bffService.getTwitterCrisisMonitorRealtimeAttacksEvolutionByHour();
      this.updateAttacksEvolutionByHour({
        data: response,
      });
    } catch (error: any) {
      this.updateAttacksEvolutionByHour({
        error: error?.message,
      });
    } finally {
      this.updateAttacksEvolutionByHour({
        loading: false,
      });
    }
  }
}
