

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import NumberMixin from "@/mixins/number.mixin";

@Component
export default class PaginationItem extends Mixins(NumberMixin) {
  @Prop({ default: 0 }) rows!: number;
  @Prop({ default: 10 }) perPage?: number;

  currentPage = 1;

  @Watch('currentPage')
  onPageChanged(currentPage: number): void {
    this.$emit('onPageChanged', currentPage)
  }
}
