const EmptyRouterView = () =>
  import(
    /* webpackChunkName: "EmptyRouterView" */ "@/components/EmptyRouterView.vue"
  );

const IFramePageKpi = () =>
  import(
    /* webpackChunkName: "IFramePageKpi" */ "@/components/KPIs/IFrame/IFramePageKpi.vue"
  );

const SectionKpiCards = () =>
  import(
    /* webpackChunkName: "SectionKpiCards" */ "@/views/KPIs/SectionKpiCards.vue"
  );

export default {
  path: "masterbrand",
  component: EmptyRouterView,
  meta: {
    labels: ["Indicadores", "Masterbrand"],
    description:
      "Pesquisa quantitativa online com recorrência trimestral que acompanha percepção sobre marcas de entretenimento e informação (Globo e concorrentes).  Público: AS, 18-65 anos, ABC. Método proprietário da Kantar Insights. ",
    customColors: ["#FF206C"],
    icons: ["Bar_Chart_4_Bars"],
    requiresPermission: "isKpiBrandUser",
  },
  children: [
    {
      path: "",
      name: "masterbrand",
      component: SectionKpiCards,
      meta: {
        labels: ["Indicadores Masterbrand"],
        hiddenOnNavigation: true,
      },
    },
    {
      path: "dimensoes-power",
      component: IFramePageKpi,
      meta: {
        labels: "Power e dimensões",
        customColors: ["#ED1D90"],
        icons: ["radio_button_checked", "table_view"],
        description: `Predisposição de escolha por determinada marca. Composto pelas dimensões Significância, Diferenciação e Saliência, índices Kantar relativos processados a cada 6 meses.`,
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection7bf5103cc4367e52a601",
        titleKpi: "Power e dimensões",
        subTitle: "Predisposição de escolha por determinada marca. Composto pelas dimensões Significância, Diferenciação e Saliência.",
      },
    },
    {
      path: "amor-a-marca",
      component: IFramePageKpi,
      meta: {
        labels: ["Amor à Marca"],
        customColors: ["#FF206C"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Conexão emocional em relação às marcas (odeio-amo).`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection1d958291545618097750",
        titleKpi: "Amor à marca",
        subTitle: "Conexão emocional em relação às marcas (odeio-amo).",
      },
    },
    {
      path: "atende-as-necessidades",
      component: IFramePageKpi,
      meta: {
        labels: ["Atende às necessidades"],
        customColors: ["#FF3E3A"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percepção sobre o quanto a marca atende às necessidades (não atende nenhuma - atende muito bem).`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSectionc32939d0bcb46914e081",
        titleKpi: "Atende às necessidades",
        subTitle: "Percepção sobre o quanto a marca atende às necessidades (não atende nenhuma - atende muito bem).",
      },
    },
    {
      path: "diferente",
      component: IFramePageKpi,
      meta: {
        labels: ["Diferente"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percepção de diferenciação em relação às marcas (a mesma coisa - muito diferente).`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSectionea05703a3844f33878c7",
        titleKpi: "Diferente",
        subTitle: "Percepção de diferenciação em relação às marcas (a mesma coisa - muito diferente).",
      },
    },
    {
      path: "dita-tendencias",
      component: IFramePageKpi,
      meta: {
        labels: ["Dita tendências"],
        customColors: ["#A11261"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percepção sobre o quanto as marcas ditam ou lançam tendências (segue/copia - dita/lança).`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection35667daab258b268dc93",
        titleKpi: "Dita tendências",
        subTitle: "Percepção sobre o quanto as marcas ditam ou lançam tendências (segue/copia - dita/lança).",
      },
    },
    {
      path: "premium",
      component: IFramePageKpi,
      meta: {
        labels: ["Premium"],
        customColors: ["#FF3E3A"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Predisposição a pagar mais por determinada marca. Índice Kantar relativo processado a cada 6 meses. Assim como o Power, é composto pelas dimensões Significância, Diferenciação e Saliência.`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSectiona7e050b393404612daee",
        titleKpi: "Premium",
        subTitle: "Predisposição a pagar mais por determinada marca. Índice Kantar relativo processado a cada 6 meses.",
      },
    },
    {
      path: "imagem-bips",
      component: IFramePageKpi,
      meta: {
        labels: ["Brand Image Profile"],
        customColors: ["#FF206C"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Indica os destaques positivos e negativos na percepção do posicionamento de uma marca frente ao set competitivo/atributos que a diferenciam.`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection47bde015bef891fe6e97",
        titleKpi: "Brand Image Profile",
        subTitle: "Indica os destaques positivos e negativos na percepção do posicionamento de uma marca frente ao set competitivo/atributos que a diferenciam.",
      },
    },
    // {
    //   path: "imagem",
    //   component: IFramePageKpi,
    //   meta: {
    //     labels: ["Imagem"],
    //     customColors: ["#ED1D90"],
    //     icons: ["view_week", "timeline", "table_view"],
    //     description: `Indica o percentual de pessoas que associaram as marcas a cada um dos atributos de imagem.`,
    //     imageFile: "",
    //     requiresPermission: "isKpiBrandUser",
    //     iFrameSrc:
    //       "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection0bc90e813b848096c511",
    //     titleKpi: "Imagem",
    //     subTitle: "Indica o percentual de pessoas que associaram as marcas a cada um dos atributos de imagem.",        
    //   },
    // },
    {
      path: "nps",
      component: IFramePageKpi,
      meta: {
        labels: ["NPS"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Net Promoter Score: % de promotores - % de detratores.`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection3acdff340de9d3199e6a",
        titleKpi: "NPS",
        subTitle: "Net Promoter Score: % de promotores - % de detratores.",
      },
    },
    // {
    //   path: "nps-conhecedores",
    //   component: IFramePageKpi,
    //   meta: {
    //     labels: ["NPS entre conhecedores"],
    //     customColors: ["#FF206C"],
    //     icons: ["view_week", "timeline", "table_view"],
    //     description: `Net Promoter Score: % de promotores - % de detratores.`,
    //     imageFile: "",
    //     requiresPermission: "isKpiBrandUser",
    //     iFrameSrc:
    //       "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSectioncc289f3382566dd5e80e",
    //     titleKpi: "NPS entre conhecedores",
    //     subTitle: "Net Promoter Score: % de promotores - % de detratores.",
    //   },
    // },
    {
      path: "consideracao",
      component: IFramePageKpi,
      meta: {
        labels: ["Consideração"],
        customColors: ["#ED1D90"],
        icons: ["view_week", "timeline", "table_view"],
        description: `É a probabilidade da pessoa escolher uma marca em uma próxima oportunidadade de consumo.`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection40a2ed1d91c01b54b761",
        titleKpi: "Consideração",
        subTitle: "É a probabilidade da pessoa escolher uma marca em uma próxima oportunidadade de consumo.",
      },
    },
    {
      path: "preferencia",
      component: IFramePageKpi,
      meta: {
        labels: ["Preferência"],
        customColors: ["#FF206C"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percentual de pessoas que preferem uma marca.`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection95c23502883980fb5154",
        titleKpi: "Preferência",
        subTitle: "Percentual de pessoas que preferem uma marca.",
      },
    },
    {
      path: "confianca",
      component: IFramePageKpi,
      meta: {
        labels: ["Confiança"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percentual de pessoas que consomem e confiam numa marca.`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc:
          "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSectionc034facf039f1db6a972",
        titleKpi: "Confiança",
        subTitle: "Percentual de pessoas que consomem e confiam numa marca.",
      },
    },
    {
      path: "familiaridade",
      component: IFramePageKpi,
      meta: {
        labels: ["Familiaridade"],
        customColors: ["#A11261"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Nível de familiaridade com as marcas (do conhecimento ao consumo).`,
        imageFile: "",
        requiresPermission: "isKpiBrandUser",
        iFrameSrc: "https://app.powerbi.com/reportEmbed?reportId=1af43e5a-b7d7-4cb6-aa55-73f09c9646fe&autoAuth=true&ctid=a7cdc447-3b29-4b41-b73e-8a2cb54b06c6&pageName=ReportSection06d39f359d86668d772a",
        titleKpi: "Familiaridade",
        subTitle: "Nível de familiaridade com as marcas (do conhecimento ao consumo).",
      },
    },
  ],
};
