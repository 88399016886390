
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class CustomIcon extends Vue {
  @Prop(String) private icon!: string;
  @Prop({ default: 700 }) private weight!: number;
  @Prop({ default: 24 }) private size!: number;

  get fontVariationSettings(): string {
    return `'FILL' ${ this.fillIcon ? '1' : '0' }, 'wght' ${ this.weight }, 'GRAD' 0, 'opsz' 48`;
  }

  get fillIcon(): boolean {
    return this.icon.endsWith('-fill');
  }

  get iconName(): string {
    return this.fillIcon ? this.icon.slice(0, -5) : this.icon;
  }

  get iconSize(): string {
    return `${this.size}px`;
  }

  get isSocialMediaIcon(): boolean {
    const socialMediaList = ['twitter', 'youtube', 'facebook', 'instagram'];
    return socialMediaList.includes(this.icon);
  }
}
