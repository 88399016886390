
import { Component, Prop, Mixins } from "vue-property-decorator";
import NumberMixin from "@/mixins/number.mixin";

@Component
export default class SingleBarChart extends Mixins(NumberMixin) {
  @Prop({ default: 0 }) public currentValue!: number;
  @Prop({ default: 0 }) private maxValue!: number;
  @Prop({ default: '#eee'}) barColor!: string;
  @Prop() customBarWidth?: number;

  get barWidth(): string {
    if (typeof this.customBarWidth === 'number') {
      return `${this.customBarWidth}%`;
    }
    const width = (this.currentValue * 100) / this.maxValue;
    return `${width}%`;
  }
}
