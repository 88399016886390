
import { Component, Vue } from 'vue-property-decorator';
import RealtimePage from './TwitterCrisisMonitor/RealtimePage.vue';
import { RouteConfig } from 'vue-router';
import RouterUtil from '@/utils/router.util';
import SectionCard from '@/components/SectionCard.vue';
@Component({
  components: {
    RealtimePage,
    SectionCard,
  },
})
export default class HomeView extends Vue {
  private sections: RouteConfig[] = [];

  getCardsData(): void {
    const routes = this.$router.options.routes;
    const cards = ['/repercussao','/kpi-marcas'];

    cards.forEach(card => {
      const r = RouterUtil.getRouteByPath(card, routes);
      if (r) this.sections.push(r);
    });
  }

  isFirstCard(currentCardIndex: number): boolean {
    return currentCardIndex === 0;
  }

  isLastCard(currentCardIndex: number, listSize: number): boolean {
    return currentCardIndex === (listSize - 1);
  }

  mounted(): void {
    this.getCardsData();
  }
}
