var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-row',[(_vm.generalRepercussion)?_c('b-col',{staticClass:"mt-2",attrs:{"sm":"6","md":"6","lg":"4"}},[_c('CrisisMonitorCard',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{attrs:{"slot":"crisis-monitor-head"},slot:"crisis-monitor-head"},[_vm._v("repercussão geral")]),_c('div',{attrs:{"slot":"crisis-monitor-body"},slot:"crisis-monitor-body"},[_c('b-row',{staticClass:"overview-content"},[_c('b-col',{staticClass:"overview-icon",attrs:{"cols":"2"}},[_c('CustomIcon',{attrs:{"icon":"chat_bubble","weight":400,"size":40}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overview-label"},[_vm._v("comentários")]),_c('div',{staticClass:"overview-number"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.generalRepercussion.tweets.total ))+" ")]),(_vm.generalRepercussion.tweets.lastPeriodComparison)?_c('div',{class:_vm.selectLastPeriodComparisonClass(
                  _vm.generalRepercussion.tweets.lastPeriodComparison
                )},[_c('b-icon',{attrs:{"icon":_vm.selectLastPeriodComparisonIcon(
                    _vm.generalRepercussion.tweets.lastPeriodComparison
                  )}}),_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.generalRepercussion.tweets.lastPeriodComparison, 1 ))+"% ")],1):_vm._e()])],1),_c('b-row',{staticClass:"orverview-separator"}),_c('b-row',{staticClass:"overview-content"},[_c('b-col',{staticClass:"overview-icon",attrs:{"cols":"2"}},[_c('CustomIcon',{attrs:{"icon":"face_5","weight":400,"size":40}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overview-label"},[_vm._v("usuários")]),_c('div',{staticClass:"overview-number"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.generalRepercussion.users.total ))+" ")]),(_vm.generalRepercussion.users.lastPeriodComparison)?_c('div',{class:_vm.selectLastPeriodComparisonClass(
                  _vm.generalRepercussion.users.lastPeriodComparison
                )},[_c('b-icon',{attrs:{"icon":_vm.selectLastPeriodComparisonIcon(
                    _vm.generalRepercussion.users.lastPeriodComparison
                  )}}),_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.generalRepercussion.users.lastPeriodComparison, 1 ))+"% ")],1):_vm._e()])],1)],1)])],1):_vm._e(),(_vm.brandAttacks)?_c('b-col',{staticClass:"mt-2",attrs:{"sm":"6","md":"6","lg":"4"}},[_c('CrisisMonitorCard',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{attrs:{"slot":"crisis-monitor-head"},slot:"crisis-monitor-head"},[_vm._v("total de ataque à marca")]),_c('div',{attrs:{"slot":"crisis-monitor-body"},slot:"crisis-monitor-body"},[_c('b-row',{staticClass:"overview-content"},[_c('b-col',{staticClass:"overview-icon",attrs:{"cols":"2"}},[_c('CustomIcon',{attrs:{"icon":"sms_failed","weight":400,"size":40}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overview-label"},[_vm._v("comentários (detratores)")]),_c('div',{staticClass:"overview-number"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion(_vm.brandAttacks.attacks.total))+" ")]),(_vm.brandAttacks.attacks.lastPeriodComparison)?_c('div',{class:_vm.selectLastPeriodComparisonClass(
                  _vm.brandAttacks.attacks.lastPeriodComparison
                )},[_c('b-icon',{attrs:{"icon":_vm.selectLastPeriodComparisonIcon(
                    _vm.brandAttacks.attacks.lastPeriodComparison
                  )}}),_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.brandAttacks.attacks.lastPeriodComparison, 1 ))+"% ")],1):_vm._e()]),_c('b-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"overview-label-percentage"},[_vm._v("participação na repercussão geral")]),_c('div',{staticClass:"overview-number-percentage"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.brandAttacks.attacks.percentageOfGeneralRepercussion, 2 ))+"% ")])])],1),_c('b-row',{staticClass:"orverview-separator"}),_c('b-row',{staticClass:"overview-content"},[_c('b-col',{staticClass:"overview-icon",attrs:{"cols":"2"}},[_c('CustomIcon',{attrs:{"icon":"sentiment_extremely_dissatisfied","weight":400,"size":40}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overview-label"},[_vm._v("usuários (detratores)")]),_c('div',{staticClass:"overview-number"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion(_vm.brandAttacks.detractors.total))+" ")]),(_vm.brandAttacks.detractors.lastPeriodComparison)?_c('div',{class:_vm.selectLastPeriodComparisonClass(
                  _vm.brandAttacks.detractors.lastPeriodComparison
                )},[_c('b-icon',{attrs:{"icon":_vm.selectLastPeriodComparisonIcon(
                    _vm.brandAttacks.detractors.lastPeriodComparison
                  )}}),_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.brandAttacks.detractors.lastPeriodComparison, 1 ))+"% ")],1):_vm._e()]),_c('b-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"overview-label-percentage"},[_vm._v("participação na repercussão geral")]),_c('div',{staticClass:"overview-number-percentage"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.brandAttacks.detractors.percentageOfGeneralRepercussion, 2 ))+"% ")])])],1)],1)])],1):_vm._e(),(_vm.botInfluenceOnAttacks)?_c('b-col',{staticClass:"mt-2",attrs:{"sm":"6","md":"6","lg":"4"}},[_c('CrisisMonitorCard',{attrs:{"isLoading":_vm.isLoading}},[_c('div',{attrs:{"slot":"crisis-monitor-head"},slot:"crisis-monitor-head"},[_vm._v("influência de robôs nos ataques")]),_c('div',{attrs:{"slot":"crisis-monitor-body"},slot:"crisis-monitor-body"},[_c('b-row',{staticClass:"overview-content"},[_c('b-col',{staticClass:"overview-icon",attrs:{"cols":"2"}},[_c('CustomIcon',{attrs:{"icon":"chat_error","weight":400,"size":40}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overview-label"},[_vm._v("comentários (ataques artificiais)")]),_c('div',{staticClass:"overview-number"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.botInfluenceOnAttacks.attacksMadeByBots.total ))+" ")]),(
                _vm.botInfluenceOnAttacks.attacksMadeByBots.lastPeriodComparison
              )?_c('div',{class:_vm.selectLastPeriodComparisonClass(
                  _vm.botInfluenceOnAttacks.attacksMadeByBots.lastPeriodComparison
                )},[_c('b-icon',{attrs:{"icon":_vm.selectLastPeriodComparisonIcon(
                    _vm.botInfluenceOnAttacks.attacksMadeByBots
                      .lastPeriodComparison
                  )}}),_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.botInfluenceOnAttacks.attacksMadeByBots .lastPeriodComparison, 1 ))+"% ")],1):_vm._e()]),_c('b-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"overview-label-percentage"},[_vm._v("participação no total de ataques")]),_c('div',{staticClass:"overview-number-percentage"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.botInfluenceOnAttacks.attacksMadeByBots.percentageOfAttacks, 2 ))+"% ")])])],1),_c('b-row',{staticClass:"orverview-separator"}),_c('b-row',{staticClass:"overview-content"},[_c('b-col',{staticClass:"overview-icon",attrs:{"cols":"2"}},[_c('CustomIcon',{attrs:{"icon":"smart_toy","weight":400,"size":40}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overview-label"},[_vm._v("usuários (perfis falsos)")]),_c('div',{staticClass:"overview-number"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.botInfluenceOnAttacks.bots.total ))+" ")]),(_vm.botInfluenceOnAttacks.bots.lastPeriodComparison)?_c('div',{class:_vm.selectLastPeriodComparisonClass(
                  _vm.botInfluenceOnAttacks.bots.lastPeriodComparison
                )},[_c('b-icon',{attrs:{"icon":_vm.selectLastPeriodComparisonIcon(
                    _vm.botInfluenceOnAttacks.bots.lastPeriodComparison
                  )}}),_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.botInfluenceOnAttacks.bots.lastPeriodComparison, 1 ))+"% ")],1):_vm._e()]),_c('b-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"overview-label-percentage"},[_vm._v("participação no total de ataques")]),_c('div',{staticClass:"overview-number-percentage"},[_vm._v(" "+_vm._s(_vm.displayNumberFormattedByRegion( _vm.botInfluenceOnAttacks.bots.percentageOfAttacks, 2 ))+"% ")])])],1)],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }