
import PaginationItem from "@/components/PaginationItem.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import { TwitterCrisisMonitorAttacksCountByBrandsItemDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-brands.dto";
import SingleBarChart from "@/components/SingleBarChart.vue";
import Pagination from "@/mixins/pagination.mixin";
import { AttacksCountByBrandsStoreContract } from "@/data/store/attacks-count-by-brands.store.contract";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
    SingleBarChart,
  },
})
export default class AttacksCountByBrands extends Mixins(Pagination) {
  @Prop() private attacksCountByBrandsStore!: AttacksCountByBrandsStoreContract;
  @Prop({ default: "" }) private startDate!: string;
  @Prop({ default: "" }) private endDate!: string;

  get attacksCountByBrands(): TwitterCrisisMonitorAttacksCountByBrandsItemDTO[] {
    return this.attacksCountByBrandsStore.paginated;
  }

  get attacksCountByBrandsCountItems(): number {
    return this.attacksCountByBrandsStore.totalCount;
  }

  get paginationLimit(): number {
    return this.$store.state.twitterCrisisMonitor.paginationLimit;
  }

  get attacksCountByTermMaxAttack(): number {
    return this.attacksCountByBrandsStore.maxAttacksCount;
  }

  get isLoading(): boolean {
    return this.attacksCountByBrandsStore.isLoading;
  }

  async getAttacksCountByBrands(offset = 0): Promise<void> {
    await this.attacksCountByBrandsStore.fetch({
      pagination: {
        offset,
        limit: this.paginationLimit,
      },
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getAttacksCountByBrands(0);
  }
}
