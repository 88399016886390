
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import Pagination from "@/mixins/pagination.mixin";
import { TwitterCrisisMonitorAttacksOfGreaterRepercussionItemDTO } from "@/data/dto/twitter-crisis-monitor/attacks-of-greater-repercussion.dto";
import SingleBarChart from "@/components/SingleBarChart.vue";
import PaginationItem from "@/components/PaginationItem.vue";
import CustomSelectList from "@/components/CustomGroupedSelectList.vue";
import { debounce } from "@/utils/debouce";
import { FilterStoreContract } from "@/data/store/filter.store.contract";
import { AttacksOfGreaterRepercussionStoreContract } from "@/data/store/attacks-of-greater-repercussion-store.contract";
import CustomVirtualScrollerSelectList from "@/components/CustomVirtualScrollerSelectList.vue";
import { TwitterCrisisMonitorAttacksCountByDatesItemDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-dates.dto";
import moment from "moment";
import { DatesWithCrisisStoreContract } from "@/data/store/dates-with-crisis.store.contract";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

const generateDefaultFilters = () => ({
  users: [],
  hashtags: [],
  mentions: [],
  brands: [],
  terms: [],
});

@Component({
  components: {
    CustomVirtualScrollerSelectList,
    PaginationItem,
    SingleBarChart,
    CrisisMonitorCard,
    CustomSelectList,
    LoadingOverlay,
  },
})
export default class AttacksOfGreaterRepercussion extends Mixins(Pagination) {
  @Prop() private startDate!: string;
  @Prop() private endDate!: string;

  @Prop() title!: string;

  @Prop() attacksOfGreaterRepercussionStore!: AttacksOfGreaterRepercussionStoreContract;
  @Prop() filterUsersStore!: FilterStoreContract;
  @Prop() filterHashtagsStore!: FilterStoreContract;
  @Prop() filterBrandsStore!: FilterStoreContract;
  @Prop() filterTermsStore!: FilterStoreContract;
  @Prop() filterMentionsStore!: FilterStoreContract;
  @Prop() filterDatesStore?: DatesWithCrisisStoreContract;

  @Prop() showSelectDate!: boolean;
  @Prop() titleSelectDate!: string;
  @Prop() subTitleSelectDate!: string;

  labelSelectAll = '<span class="mr-5">data da crise</span><span class="ml-5">ataques</span>'

  filter = generateDefaultFilters();
  selectedDates = [];

  // eslint-disable-next-line
  getAttacksOfGreaterRepercussion: any = () => null;

  get attacksOfGreaterRepercussionMaxRt() {
    return this.attacksOfGreaterRepercussionStore.maxRtCount;
  }

  get allUsers() {
    return this.filterUsersStore.allForSelect;
  }

  get isLoadingUsers(): boolean {
    return this.filterUsersStore.isLoading;
  }

  get allHashtags() {
    return this.filterHashtagsStore.allForSelect;
  }

  get isLoadingHashtags(): boolean {
    return this.filterHashtagsStore.isLoading;
  }

  get allTerms() {
    return this.filterTermsStore.allForSelect;
  }

  get isLoadingTerms(): boolean {
    return this.filterTermsStore.isLoading;
  }

  get allMentions() {
    return this.filterMentionsStore.allForSelect;
  }

  get isLoadingMentions(): boolean {
    return this.filterMentionsStore.isLoading;
  }

  get allBrands() {
    return this.filterBrandsStore.allForSelect;
  }

  get isLoadingBrands(): boolean {
    return this.filterBrandsStore.isLoading;
  }

  get allDates() {
    if (!this.filterDatesStore) return [];
    return this.filterDatesStore.all;
  }

  get isLoadingDates(): boolean {
    if (!this.filterDatesStore) return false;
    return this.filterDatesStore.isLoading;
  }

  get attacksOfGreaterRepercussion(): TwitterCrisisMonitorAttacksOfGreaterRepercussionItemDTO[] {
    return this.attacksOfGreaterRepercussionStore.paginated;
  }

  get isLoadingAttacks(): boolean {
    return this.attacksOfGreaterRepercussionStore.isLoading;
  }

  get attacksOfGreaterRepercussionItems(): number {
    return this.attacksOfGreaterRepercussionStore.totalCount || 0;
  }

  get paginationLimit(): number {
    return this.attacksOfGreaterRepercussionStore.paginationLimit;
  }

  async _getAttacksOfGreaterRepercussion(offset = 0): Promise<void> {
    const pagination = {
      offset,
      limit: this.paginationLimit,
    };
    const filters = {
      users:
        this.filter.users.length === this.allUsers.length
          ? []
          : this.filter.users,
      hashtags:
        this.filter.hashtags.length === this.allHashtags.length
          ? []
          : this.filter.hashtags,
      mentions:
        this.filter.mentions.length === this.allMentions.length
          ? []
          : this.filter.mentions,
      brands:
        this.filter.brands.length === this.allBrands.length
          ? []
          : this.filter.brands,
      terms:
        this.filter.terms.length === this.allTerms.length
          ? []
          : this.filter.terms,
      dates:
        this.selectedDates.length === this.allDates.length
          ? []
          : this.selectedDates,
    };
    await this.attacksOfGreaterRepercussionStore.fetch({
      pagination,
      filters,
      dateFilters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async loadFilters() {
    const payload = {
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
        dates: this.selectedDates,
      },
    };

    await Promise.all([
      this.filterUsersStore.fetch(payload),
      this.filterBrandsStore.fetch(payload),
      this.filterMentionsStore.fetch(payload),
      this.filterHashtagsStore.fetch(payload),
      this.filterTermsStore.fetch(payload),
    ]);
  }

  async loadDatesWithCrisis() {
    if (this.showSelectDate && this.filterDatesStore) {
      await this.filterDatesStore.fetch({
        filters: {
          startDate: this.startDate,
          endDate: this.endDate,
        }
      });
    }
  }

  formatLabel(
    item: TwitterCrisisMonitorAttacksCountByDatesItemDTO
  ): string {
    return `
      <span class="date-item">
        <span class="date-item__date">${moment(item.date).locale('pt-br').format('DD [de] MMM[.] [de] YYYY')}</span>
        <span class="date-item__attacks">${item.attacksCount}</span>
      </span>
    `;
  }

  resetFilters() {
    this.filter = generateDefaultFilters();
  }

  @Watch("filter", { deep: true })
  onChangeFilter(): void {
    this.getAttacksOfGreaterRepercussion();
  }

  @Watch("selectedDates", { deep: true })
  async onChangeSelectedDates(): Promise<void> {
    this.resetFilters();
    await this.loadFilters();
  }

  @Watch("startDate")
  onChangeStartDate(): void {
    this.resetFilters();
    this.selectedDates = [];
  }

  @Watch("endDate")
  onChangeEndDate(): void {
    this.resetFilters();
    this.selectedDates = [];
  }

  created() {
    this.getAttacksOfGreaterRepercussion = debounce(
      this._getAttacksOfGreaterRepercussion,
      1000
    );
  }

  async mounted(): Promise<void> {
    this.loadFilters();
    this.loadDatesWithCrisis();
    this.getAttacksOfGreaterRepercussion();
  }
}
