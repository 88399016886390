
import { Component, Mixins, Prop } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import NumberMixin from "@/mixins/number.mixin";
import {
  TwitterCrisisMonitorBotInfluenceOnAttacksDTO,
  TwitterCrisisMonitorBrandAttacksDTO,
  TwitterCrisisMonitorGeneralRepercussionDTO,
} from "@/data/dto/twitter-crisis-monitor/overview.dto";
import CustomIcon from "@/components/CustomIcon.vue";
import { OverviewStoreContract } from "@/data/store/overview.store.contract";

@Component({
  components: {
    CrisisMonitorCard,
    CustomIcon,
  },
})
export default class OverviewPanel extends Mixins(NumberMixin) {
  @Prop() overviewStore!: OverviewStoreContract;
  @Prop() private startDate!: string;
  @Prop() private endDate!: string;

  get generalRepercussion(): TwitterCrisisMonitorGeneralRepercussionDTO | null {
    return this.overviewStore.generalRepercussionData;
  }

  get brandAttacks(): TwitterCrisisMonitorBrandAttacksDTO | null {
    return this.overviewStore.brandAttacksData;
  }

  get botInfluenceOnAttacks(): TwitterCrisisMonitorBotInfluenceOnAttacksDTO | null {
    return this.overviewStore.botInfluenceOnAttacksData;
  }

  get isLoading(): boolean {
    return this.overviewStore.isLoading;
  }

  selectLastPeriodComparisonIcon(lastPeriodComparison: number): string {
    return lastPeriodComparison < 0 ? "arrow-down" : "arrow-up";
  }

  selectLastPeriodComparisonClass(lastPeriodComparison: number): string {
    return lastPeriodComparison < 0
      ? "last-comparison-period-decrease"
      : "last-comparison-period-increase";
  }

  async getOverview(): Promise<void> {
    await this.overviewStore.getOverview({
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getOverview();
  }
}
