
import PaginationItem from "@/components/PaginationItem.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import Pagination from "@/mixins/pagination.mixin";
import NumberMixin from "@/mixins/number.mixin";
import { TwitterCrisisMonitorBiggestInfluencersItemDTO } from "@/data/dto/twitter-crisis-monitor/biggest-influencers.dto";
import { BiggestInfluencersStoreContract } from "@/data/store/biggest-influencers-store-contract";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
  },
})
export default class BiggestInfluencers extends Mixins(
  Pagination,
  NumberMixin
) {
  @Prop() private biggestInfluencersStore!: BiggestInfluencersStoreContract;
  @Prop({ default: "" }) private startDate!: string;
  @Prop({ default: "" }) private endDate!: string;

  get biggestInfluencers(): TwitterCrisisMonitorBiggestInfluencersItemDTO[] {
    return this.biggestInfluencersStore.paginated;
  }

  get biggestInfluencersItems(): number {
    return this.biggestInfluencersStore.totalCount;
  }

  get paginationLimit(): number {
    return this.biggestInfluencersStore.paginationLimit;
  }

  get isLoading(): boolean {
    return this.biggestInfluencersStore.isLoading;
  }

  async getBiggestInfluencers(offset = 0): Promise<void> {
    await this.biggestInfluencersStore.getBiggestInfluencers({
      pagination: {
        offset,
        limit: this.paginationLimit,
      },
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getBiggestInfluencers(0);
  }
}
