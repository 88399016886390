import Vue from "vue";
import Vuex from "vuex";
import SystemModule from "./modules/system";
import UserModule from "./modules/user";
import GroupModule from "./modules/group";
import TwitterCrisisMonitorModule from "./modules/twitter-crisis-monitor/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    system: SystemModule,
    user: UserModule,
    group: GroupModule,
    twitterCrisisMonitor: TwitterCrisisMonitorModule,
  },
});
