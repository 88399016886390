
import { Component, Vue } from "vue-property-decorator";
import Menu from "@/components/Menu.vue";
import Alert from "@/components/Alert.vue";

@Component({
  components: {
    Menu,
    Alert,
  },
})
export default class App extends Vue {
  get hasCurrentUserData(): boolean {
    return !!this.$store.state.user.currentUser;
  }
}
