
import PaginationItem from "@/components/PaginationItem.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import SingleBarChart from "@/components/SingleBarChart.vue";
import Pagination from "@/mixins/pagination.mixin";
import { TwitterCrisisMonitorMainDetractorsItemDTO } from "@/data/dto/twitter-crisis-monitor/main-detractors.dto";
import { MainDetractorsStoreContract } from "@/data/store/main-detractors.store.contract";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
    SingleBarChart,
  },
})
export default class MainDetractors extends Mixins(Pagination) {
  @Prop() private mainDetractorsStore!: MainDetractorsStoreContract;

  @Prop() title!: string;

  @Prop({ default: "" }) private startDate!: string;
  @Prop({ default: "" }) private endDate!: string;

  get mainDetractors(): TwitterCrisisMonitorMainDetractorsItemDTO[] {
    return this.mainDetractorsStore.paginated;
  }

  get mainDetractorsItems(): number {
    return this.mainDetractorsStore.totalCount;
  }

  get paginationLimit(): number {
    return this.mainDetractorsStore.paginationLimit;
  }

  get mainDetractorsMaxAttack(): number {
    return this.mainDetractorsStore.maxAttacksCount;
  }

  get isLoading(): boolean {
    return this.mainDetractorsStore.isLoading;
  }

  async getMainDetractors(offset = 0): Promise<void> {
    await this.mainDetractorsStore.fetch({
      pagination: {
        offset,
        limit: this.paginationLimit,
      },
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getMainDetractors(0);
  }
}
