
import { Component, Mixins, Prop } from "vue-property-decorator";
import PaginationItem from "@/components/PaginationItem.vue";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import { TwitterCrisisMonitorAttacksCountByTermsItemDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-terms.dto";
import SingleBarChart from "@/components/SingleBarChart.vue";
import Pagination from "@/mixins/pagination.mixin";
import { AttacksCountByTermsStoreContract } from "@/data/store/attacks-count-by-terms.store.contract";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
    SingleBarChart,
  },
})
export default class AttacksCountByTerms extends Mixins(Pagination) {
  @Prop() private attacksCountByTermsStore!: AttacksCountByTermsStoreContract;
  @Prop({ default: "" }) private startDate!: string;
  @Prop({ default: "" }) private endDate!: string;

  get paginationLimit(): number {
    return this.attacksCountByTermsStore.paginationLimit;
  }

  get attacksCountByTerm(): TwitterCrisisMonitorAttacksCountByTermsItemDTO[] {
    return this.attacksCountByTermsStore.paginated;
  }

  get attacksCountByTermCountItems(): number {
    return this.attacksCountByTermsStore.totalCount;
  }

  get attacksCountByTermMaxAttack(): number {
    return this.attacksCountByTermsStore.maxAttacksCount;
  }

  get isLoading(): boolean {
    return this.attacksCountByTermsStore.isLoading;
  }

  async getAttacksCountByTerms(offset = 0): Promise<void> {
    await this.attacksCountByTermsStore.fetch({
      pagination: {
        offset,
        limit: this.paginationLimit,
      },
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getAttacksCountByTerms(0);
  }
}
