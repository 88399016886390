
import PaginationItem from "@/components/PaginationItem.vue";
import { Component, Mixins } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import Pagination from "@/mixins/pagination.mixin";
import { TwitterCrisisMonitorLastHoursDiagnosisDTO } from "@/data/dto/twitter-crisis-monitor/last-hours-diagnosis.dto";
import LastHourDiagnosisChart from "./LastHourDiagnosisChart.vue";
import { getModule } from "vuex-module-decorators";
import { LastHourDiagnosisStore } from "@/store/modules/twitter-crisis-monitor/realtime/last-hour-diagnosis";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
    LastHourDiagnosisChart,
  },
})
export default class LastHourDiagnosis extends Mixins(Pagination) {
  lastHourDiagnosisStore = getModule(LastHourDiagnosisStore, this.$store);

  get lastHoursDiagnosis(): TwitterCrisisMonitorLastHoursDiagnosisDTO | null {
    return this.lastHourDiagnosisStore.all;
  }

  get isLoading(): boolean {
    return this.lastHourDiagnosisStore.isLoading;
  }

  async getLastHourDiagnosis(): Promise<void> {
    await this.lastHourDiagnosisStore.getLastHoursDiagnosis();
  }

  async mounted(): Promise<void> {
    await this.getLastHourDiagnosis();
  }
}
