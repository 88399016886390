import { HttpClient } from "@/core/http-client";
import {
  PaginatedRequestParamsDTO,
  ResponseWithMetaDTO,
} from "@/data/dto/common.dto";
import { CredentialsDTO } from "@/data/dto/credentials.dto";
import { GroupDTO } from "@/data/dto/group.dto";
import { ResearchFilterDTO } from "@/data/dto/research-filter.dto";
import { SessionDTO } from "@/data/dto/session.dto";
import { TwitterCrisisMonitorAttacksCountByBrandsDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-brands.dto";
import { TwitterCrisisMonitorAttacksCountByHashtagsDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-hashtags.dto";
import { TwitterCrisisMonitorAttacksCountByMentionsDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-mentions.dto";
import { TwitterCrisisMonitorAttacksCountByTermsDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-terms.dto";
import { TwitterCrisisMonitorAttacksEvolutionByHourDTO } from "@/data/dto/twitter-crisis-monitor/attacks-evolution-by-hour.dto";
import { TwitterCrisisMonitorBiggestInfluencersDTO } from "@/data/dto/twitter-crisis-monitor/biggest-influencers.dto";
import { TwitterCrisisMonitorLastHoursDiagnosisDTO } from "@/data/dto/twitter-crisis-monitor/last-hours-diagnosis.dto";
import { TwitterCrisisMonitorMainDetractorsDTO } from "@/data/dto/twitter-crisis-monitor/main-detractors.dto";
import { TwitterCrisisMonitorOverviewDTO } from "@/data/dto/twitter-crisis-monitor/overview.dto";
import { TwitterCrisisMonitorCrisisRankingByDateDTO } from "@/data/dto/twitter-crisis-monitor/crisis-ranking-by-date.dto";
import { UserDTO } from "@/data/dto/user.dto";
import {
  TwitterCrisisMonitorAttackFiltersDTO,
  TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO,
} from "@/data/dto/twitter-crisis-monitor/attacks-of-greater-repercussion.dto";
import {
  TwitterCrisisMonitorAttackDateFiltersDTO,
  TwitterCrisisMonitorDateListFilterDTO,
} from "@/data/dto/twitter-crisis-monitor/date-filters.dto";
import { TwitterCrisisMonitorAttacksEvolutionByDateDTO } from "@/data/dto/twitter-crisis-monitor/attacks-evolution-by-date.dto";
import { TwitterCrisisMonitorParticipationOfRobotsInAttacksByDateDTO } from "@/data/dto/twitter-crisis-monitor/participation-of-robots-in-attacks-by-date.dto";
import {
  TwitterCrisisMonitorBotWithDeterminingPercentageDTO,
  TwitterCrisisMonitorUserCriteriaListForDeterminingAsBotDTO,
} from "@/data/dto/twitter-crisis-monitor/criteria-of-fake-profiles";
import { TwitterCrisisMonitorAttacksCountByMonthAndYearDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-month-and-year.dto";
import { TwitterCrisisMonitorAttacksCountByDatesDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-dates.dto";
import { TwitterCrisisMonitorCrisisRankingByMonthDTO } from "@/data/dto/twitter-crisis-monitor/crisis-ranking-by-month.dto";

type UsersResponse = { users: UserDTO[] };
type GroupsResponse = { groups: GroupDTO[] };
type Permissions = { key: string; value: string };
type SimpleUserResponse = { name: string; email: string }[];
type ConfigsResponse = { features: ResearchFilterDTO[] };

export interface IMOMABffService {
  login(credentials: CredentialsDTO): Promise<SessionDTO>;
  authorize(): Promise<UserDTO>;
  getAllUsers(): Promise<UsersResponse>;
  getOneUser(email: string): Promise<GroupsResponse>;
  createUser(email: string, name: string, groups: string[]): Promise<void>;
  deleteUser(email: string): Promise<void>;
  updateUserStatus(email: string, active: boolean): Promise<void>;
  updateUserName(email: string, name: string): Promise<void>;
  addUserToApplicationGroup(email: string, groups: string[]): Promise<void>;
  removeUserFromApplicationGroup(
    email: string,
    groupUUID: string
  ): Promise<void>;
  getAllGroups(): Promise<GroupsResponse>;
  createGroup(
    name: string,
    description: string,
    permissions: Permissions[],
    hidden?: boolean
  ): Promise<void>;
  updateGroupStatus(uuid: string, status: boolean): Promise<void>;
  updateGroup(
    uuid: string,
    description: string,
    name: string,
    permissions: Permissions[],
    hidden?: boolean
  ): Promise<void>;
  deleteGroup(uuid: string): Promise<void>;
  getOneGroup(uuid: string): Promise<GroupDTO>;
  addMultipleUsersToApplicationGroup(
    uuid: string,
    users: string[]
  ): Promise<void>;
  getUsersFromGroup(uuid: string): Promise<SimpleUserResponse>;
  simulateUser(email: string): Promise<SessionDTO>;
  getAuthorizationConfigs(): Promise<ConfigsResponse>;
  getTwitterCrisisMonitorRealtimeOverview(): Promise<TwitterCrisisMonitorOverviewDTO>;
  getTwitterCrisisMonitorRealtimeAttacksCountByTerms(
    queryParams: PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>>;
  getTwitterCrisisMonitorRealtimeAttacksCountByBrands(
    queryParams: PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>>;
  getTwitterCrisisMonitorRealtimeAttacksCountByMentions(
    queryParams: PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
  >;
  getTwitterCrisisMonitorRealtimeAttacksCountByHashtags(
    queryParams: PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
  >;
  getTwitterCrisisMonitorRealtimeBiggestInfluencers(
    queryParams?: PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorBiggestInfluencersDTO>>;
  getTwitterCrisisMonitorRealtimeMainDetractors(
    queryParams?: PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorMainDetractorsDTO>>;
  getTwitterCrisisMonitorRealtimeLastHoursDiagnosis(): Promise<TwitterCrisisMonitorLastHoursDiagnosisDTO>;
  getTwitterCrisisMonitorRealtimeAttacksEvolutionByHour(): Promise<TwitterCrisisMonitorAttacksEvolutionByHourDTO>;
  getTwitterCrisisMonitorRealtimeAttacksOfGreaterRepercussion(
    queryParams?: PaginatedRequestParamsDTO,
    filter?: TwitterCrisisMonitorAttackFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
  >;
  getTwitterCrisisMonitorHistoricDataAttacksCountByTerms(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>>;
  getTwitterCrisisMonitorHistoricDataAttacksCountByBrands(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>>;
  getTwitterCrisisMonitorHistoricDataAttacksCountByMentions(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
  >;
  getTwitterCrisisMonitorHistoricDataAttacksCountByHashtags(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
  >;
  getTwitterCrisisMonitorHistoricalOverview(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorOverviewDTO>;

  getTwitterCrisisMonitorHistoricalAttacksEvolutionByDate(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorAttacksEvolutionByDateDTO>;

  getTwitterCrisisMonitorHistoricTermsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ terms: string[] }>>;

  getTwitterCrisisMonitorHistoricBrandsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ brands: string[] }>>;

  getTwitterCrisisMonitorHistoricMentionsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ mentions: string[] }>>;

  getTwitterCrisisMonitorHistoricHashtagsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ hashtags: string[] }>>;

  getTwitterCrisisMonitorHistoricUsersToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ users: string[] }>>;

  getTwitterCrisisMonitorHistoricalAttacksOfGreaterRepercussion(
    queryParams?: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO,
    filters?: TwitterCrisisMonitorAttackFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
  >;

  getTwitterCrisisMonitorHistoricalMainDetractors(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorMainDetractorsDTO>>;
  getTwitterCrisisMonitorHistoricalBiggestInfluencers(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorBiggestInfluencersDTO>>;

  getTwitterCrisisMonitorHistoricalBotOverview(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorOverviewDTO>;

  getTwitterCrisisMonitorHistoricBotTermsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ terms: string[] }>>;
  getTwitterCrisisMonitorHistoricBotBrandsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ brands: string[] }>>;

  getTwitterCrisisMonitorHistoricBotMentionsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ mentions: string[] }>>;

  getTwitterCrisisMonitorHistoricBotHashtagsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ hashtags: string[] }>>;

  getTwitterCrisisMonitorHistoricBotUsersToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<{ users: string[] }>>;

  getTwitterCrisisMonitorHistoricalBotParticipationOfRobotsInAttacksByDate(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorParticipationOfRobotsInAttacksByDateDTO>;
  getTwitterCrisisMonitorHistoricBotDataAttacksCountByTerms(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>>;
  getTwitterCrisisMonitorHistoricBotDataAttacksCountByBrands(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>>;
  getTwitterCrisisMonitorHistoricBotDataAttacksCountByMentions(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
  >;

  getTwitterCrisisMonitorHistoricalBotWithDeterminingPercentageToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorBotWithDeterminingPercentageDTO>
  >;

  getTwitterCrisisMonitorHistoricalBotWithDeterminingCriteriaList(params: {
    userId: string;
  }): Promise<TwitterCrisisMonitorUserCriteriaListForDeterminingAsBotDTO>;

  getTwitterCrisisMonitorHistoricalBotAttacksOfGreaterRepercussion(
    queryParams?: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO,
    filters?: TwitterCrisisMonitorAttackFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
  >;

  getTwitterCrisisMonitorHistoricalCrisisFileOverview(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorOverviewDTO>;

  getTwitterCrisisMonitorHistoricalCrisisFileAttacksCountByMonthAndYear(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorAttacksCountByMonthAndYearDTO>;

  getTwitterCrisisMonitorHistoricCrisisRankingByDate(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorCrisisRankingByDateDTO>>;

  getTwitterCrisisMonitorHistoricCrisisRankingByMonth(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<ResponseWithMetaDTO<TwitterCrisisMonitorCrisisRankingByMonthDTO>>;

  getTwitterCrisisMonitorHistoricCrisisFileTermsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ terms: string[] }>>;
  getTwitterCrisisMonitorHistoricCrisisFileBrandsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ brands: string[] }>>;

  getTwitterCrisisMonitorHistoricCrisisFileMentionsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ mentions: string[] }>>;

  getTwitterCrisisMonitorHistoricCrisisFileHashtagsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ hashtags: string[] }>>;

  getTwitterCrisisMonitorHistoricCrisisFileUsersToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ users: string[] }>>;

  getTwitterCrisisMonitorHistoricCrisisFileDatesWithCrisis(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorAttacksCountByDatesDTO>;

  getTwitterCrisisMonitorHistoricalCrisisFileAttacksOfGreaterRepercussion(
    queryParams?: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO,
    filters?: TwitterCrisisMonitorAttackFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
  >;
}

export default class MOMABffService
  extends HttpClient
  implements IMOMABffService
{
  constructor() {
    super(process.env.VUE_APP_MOMA_BFF_HOST!);
  }

  // session
  login = (credentials: CredentialsDTO): Promise<SessionDTO> =>
    this.client.post("/v1/users/current/auth", {
      code: credentials.code,
    });

  authorize = (): Promise<UserDTO> => this.client.get("/v1/users/current/auth");

  simulateUser = (email: string): Promise<SessionDTO> =>
    this.client.post("/v1/users/simulate", {
      email,
    });

  // users
  getAllUsers = (): Promise<UsersResponse> => this.client.get("/v1/users");
  getOneUser = (email: string) =>
    this.client.get<GroupsResponse>(`/v1/users/${email}`);
  createUser = (email: string, name: string, groups: string[]) =>
    this.client.post<void>("/v1/users", {
      email,
      name,
      groups,
    });
  deleteUser = (email: string) =>
    this.client.delete<void>("/v1/users", {
      data: {
        email,
      },
    });
  updateUserStatus = (email: string, active: boolean) =>
    this.client.put<void>("/v1/users/status", {
      email,
      active,
    });
  updateUserName = (email: string, name: string) =>
    this.client.put<void>("/v1/users/name", {
      email,
      name,
    });
  addUserToApplicationGroup = (email: string, groups: string[]) =>
    this.client.put<void>(`/v1/users/${email}`, {
      groups,
    });
  removeUserFromApplicationGroup = (email: string, groupUUID: string) =>
    this.client.delete<void>(`/v1/users/${email}`, {
      data: {
        groupUUID,
      },
    });

  // groups
  getAllGroups = () => this.client.get<GroupsResponse>("/v1/groups");
  createGroup = (
    name: string,
    description: string,
    permissions: Permissions[],
    hidden?: boolean
  ) =>
    this.client.post<void>("/v1/groups/", {
      name,
      description,
      permissions,
      hidden,
    });
  updateGroupStatus = (uuid: string, status: boolean) =>
    this.client.put<void>("/v1/groups/", {
      uuid,
      status,
    });
  updateGroup = (
    uuid: string,
    name: string,
    description: string,
    permissions: Permissions[],
    hidden?: boolean
  ) =>
    this.client.put<void>(`/v1/groups/${uuid}`, {
      name,
      description,
      permissions,
      hidden,
    });
  deleteGroup = (uuid: string) =>
    this.client.delete<void>(`/v1/groups/${uuid}`);
  getOneGroup = (uuid: string) =>
    this.client.get<GroupDTO>(`/v1/groups/${uuid}`);
  addMultipleUsersToApplicationGroup = (uuid: string, users: string[]) =>
    this.client.post<void>(`/v1/groups/${uuid}`, {
      users,
    });
  getUsersFromGroup = (uuid: string): Promise<SimpleUserResponse> =>
    this.client.get<SimpleUserResponse>(`v1/groups/${uuid}/show_users`);
  getAuthorizationConfigs = () =>
    this.client.get<ConfigsResponse>("/v1/authorization/configs");

  // TwitterCrisisMonitorRealtime
  getTwitterCrisisMonitorRealtimeOverview = async () =>
    await this.client.get<TwitterCrisisMonitorOverviewDTO>(
      "/v1/twitter-crisis-monitor/realtime/general/overview"
    );

  getTwitterCrisisMonitorRealtimeAttacksCountByTerms = async (
    queryParams?: PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>
    >(`/v1/twitter-crisis-monitor/realtime/general/main-terms`, {
      params: queryParams,
    });

  getTwitterCrisisMonitorRealtimeAttacksCountByBrands = async (
    queryParams?: PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>
    >(`/v1/twitter-crisis-monitor/realtime/general/main-brands`, {
      params: queryParams,
    });

  getTwitterCrisisMonitorRealtimeAttacksCountByMentions = async (
    queryParams?: PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
    >(`/v1/twitter-crisis-monitor/realtime/general/main-mentions`, {
      params: queryParams,
    });

  getTwitterCrisisMonitorRealtimeAttacksCountByHashtags = async (
    queryParams?: PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
    >(`/v1/twitter-crisis-monitor/realtime/general/main-hashtags`, {
      params: queryParams,
    });

  getTwitterCrisisMonitorRealtimeBiggestInfluencers = async (
    queryParams?: PaginatedRequestParamsDTO
  ) => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorBiggestInfluencersDTO>
    >(`/v1/twitter-crisis-monitor/realtime/general/biggest-influencers`, {
      params: queryParams,
    });
  };

  getTwitterCrisisMonitorRealtimeMainDetractors = async (
    queryParams?: PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorMainDetractorsDTO>
    >(`/v1/twitter-crisis-monitor/realtime/general/main-detractors`, {
      params: queryParams,
    });

  getTwitterCrisisMonitorRealtimeAttacksOfGreaterRepercussion = async (
    queryParams?: PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorAttackFiltersDTO
  ) =>
    await this.client.post<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
    >(
      `/v1/twitter-crisis-monitor/realtime/general/attacks-of-greater-repercussion`,
      { filters },
      { params: queryParams }
    );

  getTwitterCrisisMonitorRealtimeLastHoursDiagnosis = async () =>
    await this.client.get<TwitterCrisisMonitorLastHoursDiagnosisDTO>(
      "/v1/twitter-crisis-monitor/realtime/general/last-hours-diagnosis"
    );

  getTwitterCrisisMonitorRealtimeAttacksEvolutionByHour = async () =>
    await this.client.get<TwitterCrisisMonitorAttacksEvolutionByHourDTO>(
      "/v1/twitter-crisis-monitor/realtime/general/attacks-evolution-by-hour"
    );

  // TwitterCrisisMonitorHistoricData
  getTwitterCrisisMonitorHistoricDataAttacksCountByTerms = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>
    >(`/v1/twitter-crisis-monitor/historical/general/main-terms`, {
      params: queryParams,
    });
  };

  getTwitterCrisisMonitorHistoricDataAttacksCountByBrands = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>
    >(`/v1/twitter-crisis-monitor/historical/general/main-brands`, {
      params: queryParams,
    });
  };
  getTwitterCrisisMonitorHistoricDataAttacksCountByMentions = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
    >(`/v1/twitter-crisis-monitor/historical/general/main-mentions`, {
      params: queryParams,
    });
  };
  getTwitterCrisisMonitorHistoricDataAttacksCountByHashtags = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
    >(`/v1/twitter-crisis-monitor/historical/general/main-hashtags`, {
      params: queryParams,
    });
  };
  getTwitterCrisisMonitorHistoricalOverview = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ) =>
    await this.client.get<TwitterCrisisMonitorOverviewDTO>(
      "/v1/twitter-crisis-monitor/historical/general/overview",
      { params: queryParams }
    );

  getTwitterCrisisMonitorHistoricalAttacksEvolutionByDate = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorAttacksEvolutionByDateDTO> => {
    return await this.client.get<TwitterCrisisMonitorAttacksEvolutionByDateDTO>(
      "/v1/twitter-crisis-monitor/historical/general/attacks-evolution-by-date",
      { params: queryParams }
    );
  };

  async getTwitterCrisisMonitorHistoricTermsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      terms: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ terms: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/general/filters/terms`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricBrandsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      brands: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ brands: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/general/filters/brands`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricMentionsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      mentions: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ mentions: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/general/filters/mentions`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricHashtagsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      hashtags: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ hashtags: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/general/filters/hashtags`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricUsersToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      users: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ users: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/general/filters/users`,
      {
        params: queryParams,
      }
    );
  }

  getTwitterCrisisMonitorHistoricalAttacksOfGreaterRepercussion = async (
    queryParams?: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO,
    filters?: TwitterCrisisMonitorAttackFiltersDTO
  ) =>
    await this.client.post<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
    >(
      `/v1/twitter-crisis-monitor/historical/general/attacks-of-greater-repercussion`,
      { filters },
      { params: queryParams }
    );

  getTwitterCrisisMonitorHistoricalMainDetractors = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorMainDetractorsDTO>
    >(`/v1/twitter-crisis-monitor/historical/general/main-detractors`, {
      params: queryParams,
    });
  getTwitterCrisisMonitorHistoricalBiggestInfluencers = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorBiggestInfluencersDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorBiggestInfluencersDTO>
    >(`/v1/twitter-crisis-monitor/historical/general/biggest-influencers`, {
      params: queryParams,
    });
  };

  //TwitterCrisisMonitorHistoricBot
  getTwitterCrisisMonitorHistoricalBotOverview = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ) =>
    await this.client.get<TwitterCrisisMonitorOverviewDTO>(
      "/v1/twitter-crisis-monitor/historical/bot/overview",
      { params: queryParams }
    );

  async getTwitterCrisisMonitorHistoricBotTermsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      terms: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ terms: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/bot/filters/terms`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricBotBrandsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      brands: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ brands: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/bot/filters/brands`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricBotMentionsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      mentions: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ mentions: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/bot/filters/mentions`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricBotHashtagsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      hashtags: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ hashtags: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/bot/filters/hashtags`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricBotUsersToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<{
      users: string[];
    }>
  > {
    return await this.client.get<ResponseWithMetaDTO<{ users: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/bot/filters/users`,
      {
        params: queryParams,
      }
    );
  }

  getTwitterCrisisMonitorHistoricalBotParticipationOfRobotsInAttacksByDate =
    async (queryParams: TwitterCrisisMonitorAttackDateFiltersDTO) =>
      await this.client.get<TwitterCrisisMonitorParticipationOfRobotsInAttacksByDateDTO>(
        `/v1/twitter-crisis-monitor/historical/bot/participation-of-robots-in-attacks-by-date`,
        {
          params: queryParams,
        }
      );

  getTwitterCrisisMonitorHistoricalBotAttacksOfGreaterRepercussion = async (
    queryParams?: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO,
    filters?: TwitterCrisisMonitorAttackFiltersDTO
  ) =>
    await this.client.post<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
    >(
      `/v1/twitter-crisis-monitor/historical/bot/attacks-of-greater-repercussion`,
      { filters },
      { params: queryParams }
    );

  getTwitterCrisisMonitorHistoricalBotMainDetractors = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorMainDetractorsDTO>
    >(`/v1/twitter-crisis-monitor/historical/bot/main-detractors`, {
      params: queryParams,
    });

  getTwitterCrisisMonitorHistoricBotDataAttacksCountByTerms = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByTermsDTO>
    >(`/v1/twitter-crisis-monitor/historical/bot/main-terms`, {
      params: queryParams,
    });
  };

  getTwitterCrisisMonitorHistoricBotDataAttacksCountByBrands = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByBrandsDTO>
    >(`/v1/twitter-crisis-monitor/historical/bot/main-brands`, {
      params: queryParams,
    });
  };
  getTwitterCrisisMonitorHistoricBotDataAttacksCountByMentions = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByMentionsDTO>
    >(`/v1/twitter-crisis-monitor/historical/bot/main-mentions`, {
      params: queryParams,
    });
  };
  getTwitterCrisisMonitorHistoricBotDataAttacksCountByHashtags = async (
    queryParams: PaginatedRequestParamsDTO &
      TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
  > => {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorAttacksCountByHashtagsDTO>
    >(`/v1/twitter-crisis-monitor/historical/bot/main-hashtags`, {
      params: queryParams,
    });
  };

  async getTwitterCrisisMonitorHistoricalBotWithDeterminingPercentageToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ): Promise<
    ResponseWithMetaDTO<TwitterCrisisMonitorBotWithDeterminingPercentageDTO>
  > {
    return await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorBotWithDeterminingPercentageDTO>
    >(
      `/v1/twitter-crisis-monitor/historical/bot/criteria-for-determining-bots/users`,
      {
        params: queryParams,
      }
    );
  }

  async getTwitterCrisisMonitorHistoricalBotWithDeterminingCriteriaList(params: {
    userId: string;
  }): Promise<TwitterCrisisMonitorUserCriteriaListForDeterminingAsBotDTO> {
    return await this.client.get<TwitterCrisisMonitorUserCriteriaListForDeterminingAsBotDTO>(
      `/v1/twitter-crisis-monitor/historical/bot/criteria-for-determining-bots/users/${params.userId}`
    );
  }

  //TwitterCrisisMonitorHistoricCrisisFile
  getTwitterCrisisMonitorHistoricalCrisisFileOverview = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ) =>
    await this.client.get<TwitterCrisisMonitorOverviewDTO>(
      "/v1/twitter-crisis-monitor/historical/crisis-file/overview",
      { params: queryParams }
    );

  getTwitterCrisisMonitorHistoricalCrisisFileAttacksCountByMonthAndYear =
    async (queryParams: TwitterCrisisMonitorAttackDateFiltersDTO) =>
      await this.client.get<TwitterCrisisMonitorAttacksCountByMonthAndYearDTO>(
        "/v1/twitter-crisis-monitor/historical/crisis-file/attacks-count-by-month-and-year",
        { params: queryParams }
      );

  getTwitterCrisisMonitorHistoricalCrisisAttacksEvolutionByDate = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorAttacksEvolutionByDateDTO> => {
    return await this.client.get<TwitterCrisisMonitorAttacksEvolutionByDateDTO>(
      "/v1/twitter-crisis-monitor/historical/crisis-file/attacks-evolution-by-date",
      { params: queryParams }
    );
  };

  getTwitterCrisisMonitorHistoricCrisisRankingByMonth = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorCrisisRankingByMonthDTO>
    >(
      "/v1/twitter-crisis-monitor/historical/crisis-file/crisis-ranking-by-month",
      { params: queryParams }
    );

  getTwitterCrisisMonitorHistoricCrisisRankingByDate = async (
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO
  ) =>
    await this.client.get<
      ResponseWithMetaDTO<TwitterCrisisMonitorCrisisRankingByDateDTO>
    >(
      "/v1/twitter-crisis-monitor/historical/crisis-file/crisis-ranking-by-date",
      { params: queryParams }
    );

  getTwitterCrisisMonitorHistoricalCrisisFileAttacksOfGreaterRepercussion =
    async (
      queryParams?: PaginatedRequestParamsDTO &
        TwitterCrisisMonitorAttackDateFiltersDTO,
      filters?: TwitterCrisisMonitorAttackFiltersDTO
    ) =>
      await this.client.post<
        ResponseWithMetaDTO<TwitterCrisisMonitorAttacksOfGreaterRepercussionDTO>
      >(
        `/v1/twitter-crisis-monitor/historical/crisis-file/attacks-of-greater-repercussion`,
        { filters },
        { params: queryParams }
      );

  async getTwitterCrisisMonitorHistoricCrisisFileTermsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ terms: string[] }>> {
    return await this.client.post<ResponseWithMetaDTO<{ terms: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/crisis-file/filters/terms`,
      { filters },
      { params: queryParams }
    );
  }

  async getTwitterCrisisMonitorHistoricCrisisFileBrandsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ brands: string[] }>> {
    return await this.client.post<ResponseWithMetaDTO<{ brands: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/crisis-file/filters/brands`,
      { filters },
      { params: queryParams }
    );
  }

  async getTwitterCrisisMonitorHistoricCrisisFileMentionsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ mentions: string[] }>> {
    return await this.client.post<ResponseWithMetaDTO<{ mentions: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/crisis-file/filters/mentions`,
      { filters },
      { params: queryParams }
    );
  }

  async getTwitterCrisisMonitorHistoricCrisisFileHashtagsToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ hashtags: string[] }>> {
    return await this.client.post<ResponseWithMetaDTO<{ hashtags: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/crisis-file/filters/hashtags`,
      { filters },
      { params: queryParams }
    );
  }

  async getTwitterCrisisMonitorHistoricCrisisFileUsersToFilter(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO &
      PaginatedRequestParamsDTO,
    filters?: TwitterCrisisMonitorDateListFilterDTO
  ): Promise<ResponseWithMetaDTO<{ users: string[] }>> {
    return await this.client.post<ResponseWithMetaDTO<{ users: string[] }>>(
      `/v1/twitter-crisis-monitor/historical/crisis-file/filters/users`,
      { filters },
      { params: queryParams }
    );
  }

  async getTwitterCrisisMonitorHistoricCrisisFileDatesWithCrisis(
    queryParams: TwitterCrisisMonitorAttackDateFiltersDTO
  ): Promise<TwitterCrisisMonitorAttacksCountByDatesDTO> {
    return await this.client.get<TwitterCrisisMonitorAttacksCountByDatesDTO>(
      `/v1/twitter-crisis-monitor/historical/crisis-file/dates-with-crisis`,
      {
        params: queryParams,
      }
    );
  }
}
