import {
  TwitterCrisisMonitorAttacksCountByHashtagsDTO,
  TwitterCrisisMonitorAttacksCountByHashtagsItemDTO,
} from "@/data/dto/twitter-crisis-monitor/attacks-count-by-hashtags.dto";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  PaginatedRequestParamsDTO,
  ResponseMetaTypeDTO,
} from "@/data/dto/common.dto";
import store from "@/store";
import MOMABffService from "@/services/moma-bff.service";
import { AttacksCountByHashtagsStoreContract } from "@/data/store/attacks-count-by-hashtags.store.contract";
import { TwitterCrisisMonitorAttackDateFiltersDTO } from "@/data/dto/twitter-crisis-monitor/date-filters.dto";
import { FilterStoreContract } from "@/data/store/filter.store.contract";

type BasicState<T> = {
  loading: boolean;
  data: T | null;
  meta?: ResponseMetaTypeDTO;
  error: string | null;
  pagination?: PaginatedRequestParamsDTO;
};

type LocalState = BasicState<TwitterCrisisMonitorAttacksCountByHashtagsDTO>;

@Module({
  dynamic: true,
  namespaced: true,
  name: "AttacksCountByHashtagsStore",
  store,
})
export class AttacksCountByHashtagsStore
  extends VuexModule
  implements AttacksCountByHashtagsStoreContract, FilterStoreContract
{
  bffService = new MOMABffService();

  paginationLimit = 10;

  _state: LocalState = {
    loading: false,
    error: null,
    data: null,
    pagination: {
      offset: 0,
      limit: 10,
    },
  };

  get isLoading(): boolean {
    return this._state.loading;
  }

  get all(): TwitterCrisisMonitorAttacksCountByHashtagsItemDTO[] {
    if (!this._state.data) {
      return [];
    }

    return this._state.data.attacksCountByHashtag;
  }

  get allForSelect() {
    return this.all.map((item) => item.hashtag);
  }

  get maxAttacksCount(): number {
    return this.all[0].attacksCount;
  }

  get paginated(): TwitterCrisisMonitorAttacksCountByHashtagsItemDTO[] {
    const start = this._state.pagination?.offset || 0;
    const end = start + (this._state.pagination?.limit || this.paginationLimit);

    return this.all.slice(start, end);
  }

  get totalCount(): number {
    return this._state.meta?.totalCount || 0;
  }

  @Mutation
  updateAttacksCountByHashtagsState(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload,
    };
  }

  @Action
  async fetch(payload: {
    pagination?: PaginatedRequestParamsDTO;
    filters?: TwitterCrisisMonitorAttackDateFiltersDTO;
  }): Promise<void> {
    const { pagination } = payload;

    const validatedPagination = pagination || {
      limit: this.paginationLimit,
      offset: 0,
    };

    if (this._state.data) {
      this.updateAttacksCountByHashtagsState({
        pagination: validatedPagination,
      });
      return;
    }

    await this._getAttacksCountByHashtags();
  }

  @Action
  async _getAttacksCountByHashtags(): Promise<void> {
    this.updateAttacksCountByHashtagsState({
      loading: true,
      error: null,
      meta: undefined,
      pagination: {
        offset: 0,
        limit: 10,
      },
    });

    try {
      const response =
        await this.bffService.getTwitterCrisisMonitorRealtimeAttacksCountByHashtags();
      this.updateAttacksCountByHashtagsState({
        data: response.data,
        meta: response.meta,
      });
    } catch (error: any) {
      this.updateAttacksCountByHashtagsState({
        error: error?.message,
      });
    } finally {
      this.updateAttacksCountByHashtagsState({
        loading: false,
      });
    }
  }
}
