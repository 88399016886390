
import { Component, Prop, Vue } from 'vue-property-decorator';
import UIUtil from '@/utils/ui.util';
import CustomIcon from "@/components/CustomIcon.vue";

export interface ISectionCardData {
  link: string;
  title?: string;
  text?: string;
  customColors? : string[];
  fixedHeight?: number;
  externalLink?: boolean;
  blocked?: boolean;
  isNew?: boolean;
}

@Component({
  components: {
    CustomIcon,
  },
})
export default class SectionCard extends Vue {  
  @Prop([String, Array]) private title?: string | string[];
  @Prop(String) private text?: string;
  @Prop(String) private link!: string;
  @Prop(Array) private customColors?: string[];
  //@Prop(Number) private fixedHeight?: number;
  @Prop({ default: false }) private externalLink!: boolean;
  @Prop({ default: false }) private blocked!: boolean;
  @Prop({ default: false }) private isNew!: boolean;
  @Prop({ default: false }) private isFirstCard!: boolean;
  @Prop({ default: false }) private isLastCard!: boolean;
  @Prop(Array) private icons?: string[];

  // get cardSize(): string {
  //   return this.fixedHeight ? `${this.fixedHeight}px` : 'auto';
  // }

  get cardTitle(): string {
    if (!this.title) return '';
    return Array.isArray(this.title) ? this.title[0] : this.title;
  }

  get cardSubtitles(): string[] {
    return Array.isArray(this.title) ? this.title.slice(1) : [];
  }
  
  generateSrc(fileName: string): string {
    return UIUtil.generateImagePathbyFileName(fileName);
  }

  generateGradient(colorArray:string[]): string {
    return UIUtil.generateGradient(colorArray);
  }

  goToLink(link: string, blocked = false, externalLink = false): void {
    if (blocked) return;
    if (externalLink) {
      window.open(link, '_blank');
    } else {
      this.$router.push(link);
    }
  }
}
