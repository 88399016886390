
import PaginationItem from "@/components/PaginationItem.vue";
import { Component, Mixins, Prop } from "vue-property-decorator";
import CrisisMonitorCard from "./CrisisMonitorCard.vue";
import { TwitterCrisisMonitorAttacksCountByHashtagsItemDTO } from "@/data/dto/twitter-crisis-monitor/attacks-count-by-hashtags.dto";
import SingleBarChart from "@/components/SingleBarChart.vue";
import Pagination from "@/mixins/pagination.mixin";
import { AttacksCountByHashtagsStoreContract } from "@/data/store/attacks-count-by-hashtags.store.contract";

@Component({
  components: {
    CrisisMonitorCard,
    PaginationItem,
    SingleBarChart,
  },
})
export default class AttacksCountByHashtags extends Mixins(Pagination) {
  @Prop()
  private attacksCountByHashtagsStore!: AttacksCountByHashtagsStoreContract;
  @Prop({ default: "" }) private startDate!: string;
  @Prop({ default: "" }) private endDate!: string;

  get attacksCountByHashtags(): TwitterCrisisMonitorAttacksCountByHashtagsItemDTO[] {
    return this.attacksCountByHashtagsStore.paginated;
  }

  get attacksCountByHashtagsCountItems(): number {
    return this.attacksCountByHashtagsStore.totalCount;
  }

  get paginationLimit(): number {
    return this.attacksCountByHashtagsStore.paginationLimit;
  }

  get attacksCountByHashtagMaxAttack(): number {
    return this.attacksCountByHashtagsStore.maxAttacksCount;
  }

  get isLoading(): boolean {
    return this.attacksCountByHashtagsStore.isLoading;
  }

  async getAttacksCountByHashtags(offset = 0): Promise<void> {
    await this.attacksCountByHashtagsStore.fetch({
      pagination: {
        offset,
        limit: this.paginationLimit,
      },
      filters: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
    });
  }

  async mounted(): Promise<void> {
    await this.getAttacksCountByHashtags(0);
  }
}
