
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UserAvatar extends Vue {
  get isSimulatingUser(): boolean {
    return this.$store.state.user.isSimulatingUser;
  }

  get currentUser(): string {
    if (!this.$store.state.user.currentUser) return 'Desconhecido';
    return this.$store.state.user.currentUser.name ? this.$store.state.user.currentUser.name : this.$store.state.user.currentUser.email;
  }

  getInitialsByEmail(email: string) {
    if (typeof email === "string" && !email) {
       return "??";
    }

    const name = email.split("@")[0];

    const nameAsArray = name.split(".");

    if (nameAsArray.length < 2) {
      return nameAsArray[0].substring(0, 2).toUpperCase();
    }

    return (
      nameAsArray[0].charAt(0).toUpperCase() +
      nameAsArray[1].charAt(0).toUpperCase()
    );
  }
}
