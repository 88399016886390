import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class NumberMixin extends Vue {
  displayNumberFormattedByRegion(
    number: number,
    decimals = 0,
    stringReturnedAsNullOrUndefined = "",
    region = "pt-BR"
  ): string {
    if (isNaN(number)) return stringReturnedAsNullOrUndefined;
    return number.toLocaleString(region, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }
}
