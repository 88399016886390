
import { Component, Vue } from "vue-property-decorator";
import { IAlert } from "@/store/modules/system";

@Component({})
export default class Alert extends Vue {
  get alert(): IAlert {
    return this.$store.state.system.alert;
  }

  hide(): void {
    this.$store.commit("hideAlert");
  }

  get style(): string[] {
    const style: Record<string, string[]> = {
      success: ["bg-success"],
      warning: ["bg-warning"],
      info: ["bg-info"],
      danger: ["bg-danger"],
    };
    return style[this.alert.type];
  }
}
