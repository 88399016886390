import {
  TwitterCrisisMonitorBiggestInfluencersDTO,
  TwitterCrisisMonitorBiggestInfluencersItemDTO,
} from "@/data/dto/twitter-crisis-monitor/biggest-influencers.dto";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  PaginatedRequestParamsDTO,
  ResponseMetaTypeDTO,
} from "@/data/dto/common.dto";
import store from "@/store";
import MOMABffService from "@/services/moma-bff.service";
import { BiggestInfluencersStoreContract } from "@/data/store/biggest-influencers-store-contract";
import { TwitterCrisisMonitorAttackDateFiltersDTO } from "@/data/dto/twitter-crisis-monitor/date-filters.dto";

type BasicState<T> = {
  loading: boolean;
  data: T | null;
  meta?: ResponseMetaTypeDTO;
  error: string | null;
  pagination?: PaginatedRequestParamsDTO;
};

type LocalState = BasicState<TwitterCrisisMonitorBiggestInfluencersDTO>;

@Module({
  dynamic: true,
  namespaced: true,
  name: "BiggestInfluencersStore",
  store,
})
export class BiggestInfluencersStore
  extends VuexModule
  implements BiggestInfluencersStoreContract
{
  bffService = new MOMABffService();

  paginationLimit = 10;

  _state: LocalState = {
    loading: false,
    error: null,
    data: null,
    pagination: {
      offset: 0,
      limit: 10,
    },
  };

  get isLoading(): boolean {
    return this._state.loading;
  }

  get all(): TwitterCrisisMonitorBiggestInfluencersItemDTO[] {
    if (!this._state.data) {
      return [];
    }

    return this._state.data.biggestInfluencers;
  }

  get maxFollowersCount(): number {
    return this.all[0].followersCount;
  }

  get paginated(): TwitterCrisisMonitorBiggestInfluencersItemDTO[] {
    const start = this._state.pagination?.offset || 0;
    const end = start + (this._state.pagination?.limit || this.paginationLimit);

    return this.all.slice(start, end);
  }

  get totalCount(): number {
    return this._state.meta?.totalCount || 0;
  }

  @Mutation
  updateBiggestInfluencersState(payload: Partial<LocalState>): void {
    this._state = {
      ...this._state,
      ...payload,
    };
  }

  @Action
  async getBiggestInfluencers(payload: {
    pagination: PaginatedRequestParamsDTO;
    filters?: TwitterCrisisMonitorAttackDateFiltersDTO;
  }): Promise<void> {
    const { pagination } = payload;

    if (this._state.data) {
      this.updateBiggestInfluencersState({
        pagination,
      });
      return;
    }

    await this._getBiggestInfluencers();
  }

  @Action
  async _getBiggestInfluencers(): Promise<void> {
    this.updateBiggestInfluencersState({
      loading: true,
      error: null,
      meta: undefined,
      pagination: {
        offset: 0,
        limit: 10,
      },
    });

    try {
      const response =
        await this.bffService.getTwitterCrisisMonitorRealtimeBiggestInfluencers();
      this.updateBiggestInfluencersState({
        data: response.data,
        meta: response.meta,
      });
    } catch (error: any) {
      this.updateBiggestInfluencersState({
        error: error?.message,
      });
    } finally {
      this.updateBiggestInfluencersState({
        loading: false,
      });
    }
  }
}
