export function debounce(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: { (): void; apply?: any },
  wait: number | undefined
) {
  let timer: number;
  return function (this: typeof debounce, ...args: never[]) {
    if (timer) {
      clearTimeout(timer);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
}
