const EmptyRouterView = () =>
  import(
    /* webpackChunkName: "EmptyRouterView" */ "@/components/EmptyRouterView.vue"
  );

const IFramePageKpiEntertainmentAndVarieties = () =>
  import(
    /* webpackChunkName: "IFramePageKpiEntertainmentAndVarieties" */ "@/components/KPIs/IFrame/IFramePageKpiEntertainmentAndVarieties.vue"
  );

const SectionKpiCards = () =>
  import(
    /* webpackChunkName: "SectionKpiCards" */ "@/views/KPIs/SectionKpiCards.vue"
  );

export default {
  path: "entretenimento-e-variedades",
  component: EmptyRouterView,
  meta: {
    labels: ["Indicadores", "Entretenimento e Variedades"],
    description:
      "Pesquisa quantitativa online com recorrência anual que acompanha percepção sobre marcas de conteúdo de entretenimento e variedades. Público: AS, 18-65 anos, ABC. Método proprietário da Kantar Insights.",
    customColors: ["#FF3E3A"],
    icons: ["Bar_Chart_4_Bars"],
    requiresPermission: "isEntertainmentAndVarietiesUser",
  },
  children: [
    {
      path: "",
      name: "entretenimento-e-variedades",
      component: SectionKpiCards,
      meta: {
        labels: ["Indicadores Entretenimento e Variedades"],
        hiddenOnNavigation: true,
      },
    },
    {
      path: "dimensoes-power",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: "Power e dimensões",
        customColors: ["#ED1D90"],
        icons: ["radio_button_checked", "table_view"],
        description: `Predisposição de escolha por determinada marca. Composto pelas dimensões Significância, Diferenciação e Saliência, índices Kantar relativos processados a cada 6 meses.`,
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Power e dimensões",
        subTitle:
          "Predisposição de escolha por determinada marca. Composto pelas dimensões Significância, Diferenciação e Saliência.",
      },
    },
    {
      path: "amor-a-marca",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Amor à Marca"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Conexão emocional em relação às marcas (odeio-amo).`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Amor à Marca",
        subTitle: "Conexão emocional em relação às marcas (odeio-amo).",
      },
    },
    {
      path: "atende-as-necessidades",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Atende às necessidades"],
        customColors: ["#A11261"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percepção sobre o quanto a marca atende às necessidades (não atende nenhuma - atende muito bem).`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Atende às necessidades",
        subTitle:
          "Percepção sobre o quanto a marca atende às necessidades (não atende nenhuma - atende muito bem).",
      },
    },
    {
      path: "diferente",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Diferente"],
        customColors: ["#ED1D90"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percepção de diferenciação em relação às marcas (a mesma coisa - muito diferente).`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Diferente",
        subTitle:
          "Percepção de diferenciação em relação às marcas (a mesma coisa - muito diferente).",
      },
    },
    {
      path: "dita-tendencias",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Dita tendências"],
        customColors: ["#FF3E3A"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percepção sobre o quanto as marcas ditam ou lançam tendências (segue/copia - dita/lança).`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Dita tendências",
        subTitle:
          "Percepção sobre o quanto as marcas ditam ou lançam tendências (segue/copia - dita/lança).",
      },
    },
    {
      path: "premium",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Premium"],
        customColors: ["#FF3E3A"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Predisposição a pagar mais por determinada marca. Índice Kantar relativo processado a cada 6 meses. Assim como o Power, é composto pelas dimensões Significância, Diferenciação e Saliência.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Premium",
        subTitle:
          "Predisposição a pagar mais por determinada marca. Índice Kantar relativo processado a cada 6 meses.",
      },
    },
    {
      path: "imagem-bips",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Brand Image Profile"],
        customColors: ["#FF206C"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Indica os destaques positivos e negativos na percepção do posicionamento de uma marca frente ao set competitivo/atributos que a diferenciam.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Brand Image Profile",
        subTitle:
          "Indica os destaques positivos e negativos na percepção do posicionamento de uma marca frente ao set competitivo/atributos que a diferenciam.",
      },
    },
    // {
    //   path: "imagem",
    //   component: IFramePageKpiEntertainmentAndVarieties,
    //   meta: {
    //     labels: ["Imagem"],
    //     customColors: ["#ED1D90"],
    //     icons: ["view_week", "timeline", "table_view"],
    //     description: `Indica o percentual de pessoas que associaram as marcas a cada um dos atributos de imagem.`,
    //     imageFile: "",
    //     requiresPermission: "isEntertainmentAndVarietiesUser",
    //     iFrameSrc: "https://www.globo.com/",
    //     titleKpi: "Imagem",
    //     subTitle:
    //       "Indica o percentual de pessoas que associaram as marcas a cada um dos atributos de imagem.",
    //   },
    // },
    {
      path: "nps",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["NPS"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Net Promoter Score: % de promotores - % de detratores.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "NPS",
        subTitle: "Net Promoter Score: % de promotores - % de detratores.",
      },
    },
    // {
    //   path: "nps-conhecedores",
    //   component: IFramePageKpiEntertainmentAndVarieties,
    //   meta: {
    //     labels: ["NPS entre conhecedores"],
    //     customColors: ["#FF206C"],
    //     icons: ["view_week", "timeline", "table_view"],
    //     description: `Net Promoter Score: % de promotores - % de detratores.`,
    //     imageFile: "",
    //     requiresPermission: "isEntertainmentAndVarietiesUser",
    //     iFrameSrc: "https://www.globo.com/",
    //     titleKpi: "NPS entre conhecedores",
    //     subTitle: "Net Promoter Score: % de promotores - % de detratores.",
    //   },
    // },
    {
      path: "consideracao",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Consideração"],
        customColors: ["#ED1D90"],
        icons: ["view_week", "timeline", "table_view"],
        description: `É a probabilidade da pessoa escolher uma marca em uma próxima oportunidadade de consumo.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Consideração",
        subTitle:
          "É a probabilidade da pessoa escolher uma marca em uma próxima oportunidadade de consumo.",
        blocked: true,
      },
    },
    {
      path: "preferencia",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Preferência"],
        customColors: ["#FF206C"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percentual de pessoas que preferem uma marca.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Preferência",
        subTitle: "Percentual de pessoas que preferem uma marca.",
        blocked: true,
      },
    },
    {
      path: "confianca",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Confiança"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Percentual de pessoas que consomem e confiam numa marca.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Confiança",
        subTitle: "Percentual de pessoas que consomem e confiam numa marca.",
        blocked: true,
      },
    },
    {
      path: "familiaridade",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Familiaridade"],
        customColors: ["#A11261"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Nível de familiaridade com as marcas (do conhecimento ao consumo).`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Familiaridade",
        subTitle:
          "Nível de familiaridade com as marcas (do conhecimento ao consumo).",
        blocked: true,
      },
    },
    {
      path: "territorios-relativos",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Territórios de conteúdo (índice relativo)"],
        customColors: ["#ED1D90"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Net Promoter Score: % de promotores - % de detratores.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Territórios de conteúdo (índice relativo)",
        subTitle: "Net Promoter Score: % de promotores - % de detratores.",
        blocked: true,
      },
    },
    {
      path: "territorios-absolutos",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Territórios de conteúdo (% absoluto)"],
        customColors: ["#A11261"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Net Promoter Score: % de promotores - % de detratores.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Territórios de conteúdo (% absoluto)",
        subTitle: "Net Promoter Score: % de promotores - % de detratores.",
        blocked: true,
      },
    },
    {
      path: "ocasioes-de-consumo",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Ocasiões de consumo"],
        customColors: ["#FF206C"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Net Promoter Score: % de promotores - % de detratores.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Ocasiões de consumo",
        subTitle: "Net Promoter Score: % de promotores - % de detratores.",
        blocked: true,
      },
    },
    {
      path: "barreiras-e-facilitadores",
      component: IFramePageKpiEntertainmentAndVarieties,
      meta: {
        labels: ["Barreiras e Facilitadores"],
        customColors: ["#FF6422"],
        icons: ["view_week", "timeline", "table_view"],
        description: `Net Promoter Score: % de promotores - % de detratores.`,
        imageFile: "",
        requiresPermission: "isEntertainmentAndVarietiesUser",
        iFrameSrc: "https://www.globo.com/",
        titleKpi: "Barreiras e Facilitadores",
        subTitle: "Net Promoter Score: % de promotores - % de detratores.",
        blocked: true,
      },
    },
  ],
};
